<template>
	<div class="important-page-c display--flex fd--column">
		<!-- 搜索 -->
		<div class="search-form btn-2b5a95">
			<div class="l-w" :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }">
				<el-form label-position="right" :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          " :model="searchForm" @submit.native.prevent>
					<el-form-item label="学生:">
						<el-input v-model.trim="searchForm.qianzainame" @keyup.enter.native="seach" clearable />
					</el-form-item>
					<el-form-item label="手机:">
						<el-input v-model.trim="searchForm.mobile" @keyup.enter.native="seach" clearable />
					</el-form-item>
					<el-form-item label="院校:">
						<el-input v-model.trim="searchForm.collegename1" @keyup.enter.native="seach" clearable />
					</el-form-item>
					<el-form-item label="年级:">
						<el-input v-model.trim="searchForm.grade" @keyup.enter.native="seach" clearable />
					</el-form-item>
					<el-form-item label="确认:">
						<el-select v-model="searchForm.isokflag" @keyup.enter.native="seach"
							@visible-change="visibleisok" ref="isok" placeholder="请选择" clearable>
							<el-option value="1" label="是"></el-option>
							<el-option value="0" label="否"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="层次:">
						<el-select v-model="searchForm.levelval" @keyup.enter.native="seach"
							@visible-change="visibleLevelval" ref="levelref" placeholder="请选择" clearable>
							<el-option v-for="item in leveltypeList" :key="item.key" :label="item.val"
								:value="item.val">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="项目类型:">
						<el-select v-model="searchForm.projecttype" @keyup.enter.native="seach"
							@visible-change="projectVisible" ref="projectref" placeholder="请选择" clearable>
							<el-option v-for="item in educationList" :key="item.key" :label="item.val"
								:value="item.val">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="跟进人:">
						<el-input v-model.trim="searchForm.followusername" @keyup.enter.native="seach" clearable />
					</el-form-item>
					<el-form-item label="专业:">
						<el-input v-model.trim="searchForm.majorname1" @keyup.enter.native="seach" clearable />
					</el-form-item>
					<el-form-item label="收据:">
						<el-input v-model.trim="searchForm.shoujucode" @keyup.enter.native="seach" clearable />
					</el-form-item>
					<el-form-item label="公司:">
						<el-input v-model.trim="searchForm.companyorgname" @keyup.enter.native="seach" clearable />
					</el-form-item>
					<el-form-item label="教学点:">
						<el-input v-model.trim="searchForm.schoolorgname" @keyup.enter.native="seach" clearable />
					</el-form-item>
					<el-form-item label="录入日期起:">
						<el-date-picker v-model="searchForm.t1" type="date" placeholder="选择日期"
							value-format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="录入日期止:">
						<el-date-picker v-model="searchForm.t2" type="date" placeholder="选择日期"
							value-format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="收款时间起:">
						<el-date-picker v-model="searchForm.t3" type="date" placeholder="选择日期"
							value-format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="收款时间止:">
						<el-date-picker v-model="searchForm.t4" type="date" placeholder="选择日期"
							value-format="yyyy-MM-dd">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="生源来源:">
						<el-select v-model="searchForm.fromtypeval" @keyup.enter.native="seach"
							@visible-change="fromtyVisible" ref="fromtyref" clearable>
							<el-option v-for="item in fromtypeList" :key="item.key" :label="item.val"
								:value="item.val" />
						</el-select>
					</el-form-item>

					<el-form-item label="生源类别:">
						<el-select v-model="searchForm.studentfromtypeval" @keyup.enter.native="seach"
							@visible-change="fromtyVisible" ref="fromtyref" clearable>
							<el-option v-for="item in studentfromtypeList" :key="item.key" :label="item.val"
								:value="item.val" />
						</el-select>
					</el-form-item>
					<el-form-item label="招生年份:">
						<el-select v-model="searchForm.year" @keyup.enter.native="seach" @visible-change="yearVisible"
							ref="yearRef" clearable>
							<el-option v-for="item in yearList" :key="item.key" :label="item.label"
								:value="item.label" />
						</el-select>
					</el-form-item>
					<el-form-item label="身份证:">
						<el-input v-model.trim="searchForm.studentidcard" @keyup.enter.native="seach" clearable />
					</el-form-item>
					<el-form-item label="合作名单:">
						<el-select v-model="searchForm.iscooperation" @keyup.enter.native="seach"
							@visible-change="iscooperationVisible" ref="iscooperationRef" clearable>
							<el-option value="0" label="否"></el-option>
							<el-option value="1" label="是"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="是否有优惠:">
						<el-select
							v-model="searchForm.isdiscountfee"
							@keyup.enter.native="seach"
							@visible-change="discountfeeVisible"
							ref="isdiscountfeeRef"
							clearable>
							<el-option value="0" label="否"></el-option>
							<el-option value="1" label="是"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="优惠金额:">
						<el-input v-model.trim="searchForm.discountfee" @keyup.enter.native="seach" clearable />
					</el-form-item>
					<el-form-item label="是否欠费:">
						<el-select
							v-model="searchForm.isarrears"
							@keyup.enter.native="seach"
							@visible-change="isarrearsVisible"
							ref="isarrearsRef"
							clearable>
							<el-option value="0" label="否"></el-option>
							<el-option value="1" label="是"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="是否抵扣教材费:">
						<el-select v-model="searchForm.isbookfee" @keyup.enter.native="seach"
								   @visible-change="visibleisbookfee" ref="isbookfee" placeholder="请选择" clearable>
							<el-option value="1" label="是"></el-option>
							<el-option value="0" label="否"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>

			<div class="r-w">
				<el-button type="primary" icon="el-icon-search" @click="seach"
					:loading="!$store.state.global.isEndRequest" class="btn-2b5a95">搜索</el-button>
				<el-button type="primary" @click="resetQuery">重置</el-button>
				<el-button type="primary" :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          " v-if="$store.state.global.searchExtend_1" class="btn-2b5a95" @click="onExtendClick('isSearchExtend_1')">
					{{ $store.state.global.searchExtendText_1 }}</el-button>
			</div>
		</div>

		<!-- 按钮操作 -->
		<div class="Btn-w eaf4ff-btn" v-if="tableRes.buttonlist && tableRes.buttonlist.length" :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }">
			<div class="btn-l" v-if="tableRes.buttonlist"
				:class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'">
				<el-button v-for="(item, index) in tableRes.buttonlist" :key="index" type="primary"
					@click="onClickBtn(item.buttonname)">{{ item.name }}
				</el-button>
			</div>
			<div class="Btn-extend">
				<el-button type="primary" v-if="$store.state.global.btnExtend_1" class="btn-2b5a95" :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          " @click="onExtendClick('isBtnExtend_1')">{{ $store.state.global.BtnExtendText_1 }}</el-button>
			</div>
		</div>

		<!-- 表格 -->
		<el-table
			stripe
			border
			:header-cell-style="{ background: '#044d8c', color: '#fff'}"
			:data="tableRes.list"
			ref="multipleTable"
			style="width: 100%"
			:cell-style="{ padding: 0 }"
			@selection-change="handleSelectionChange">
			<el-table-column type="selection" width="55"></el-table-column>
			<el-table-column label="姓名/手机号" align="center" width="200">
				<template slot-scope="scope" v-if="scope.row.mobile !== null">
					<el-button @click="btnShowDetail(scope.row.qianzaiid)" type="text">
						{{
							scope.row.qianzainame
						}}
					</el-button>
					<br>
						{{ scope.row.mobile | hideMobile(scope.row.followuserid)}}
				</template>
			</el-table-column>
			<el-table-column label="院校/专业/年级/层次/学习方式" align="center" width="320">
				<template slot-scope="scope">
					{{scope.row.collegename1}}/{{scope.row.majorname1}}
					<br>
					{{scope.row.grade}}/ {{scope.row.levelval}}/ {{scope.row.studytypeval}}
				</template>
			</el-table-column>
			<el-table-column label="确认" prop="isokflag" align="center" width="80">
				<template slot-scope="scope">
				{{
          available[scope.row.isokflag]
        }}</template>
			</el-table-column>
			<el-table-column label="应收" prop="shouldrecvmoney" align="center" width="80">
			</el-table-column>
			<el-table-column label="实收" prop="realrecvmoney" align="center" width="80">
			</el-table-column>
			<el-table-column label="优惠" prop="discountfee" align="center" width="80">
			</el-table-column>

			<el-table-column label="退费" prop="feebackmoney" align="center" width="80">
			</el-table-column>
			<el-table-column label="欠费" align="center">
			  <template slot-scope="scope">
			    {{
			      Math.abs(
			        scope.row.shouldrecvmoney -
			        scope.row.realrecvmoney -
							scope.row.discountfee +
			        scope.row.feebackmoney
			      ).toFixed(2)
			    }}
			  </template>
			</el-table-column>
			<el-table-column label="收款时间" prop="shoukuantime" align="center" width="120" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					{{scope.row.shoukuantime | formatDate("YYYY-MM-DD") }}
				</template>
			</el-table-column>
			<el-table-column label="收据" prop="shoujucodes" align="center" width="150" :show-overflow-tooltip="true">
			</el-table-column>
			<el-table-column label="跟进人" prop="followusername" align="center" width="100">
			</el-table-column>
			<el-table-column label="项目类型" prop="projecttype" align="center" width="100">
			</el-table-column>
			<el-table-column label="教学点" prop="schoolorgname" align="center" width="100">
			</el-table-column>
		</el-table>
		<!-- 分页 -->
		<el-pagination background @current-change="handleCurrentChange" :current-page="pageinfo.pageindex"
			:page-size="pageinfo.pagesize" :page-sizes="[10, 20, 30, 40, 50]" layout="total, prev, pager, next, jumper"
			:total="tableRes.totalcount"></el-pagination>

		<div id="RightOffside" class="RightOffside" v-show="showDetailModal">
			<!-- 按钮组 -->
			<div class="Offside_btnBox">
				<div class="Offside_Btn Offside_CloseBtn" @click="handleShow"></div>
				<div class="Offside_Btn" v-for="(item,index) in tabList" :key="index"
					:class="{active:currentClass === index}" @click="toggleTab(index)">
					{{item}}
				</div>
			</div>
			<div class="OrderStatusTips">
				<span class="pull-left text-blue">人员信息</span>
			</div>
			<!-- 切换栏 -->
			<div>
				<div class="OrderContentBox" v-show="currentTab === 0">
					<div class="order-card x-basic abase-card"
						style="width:800px;background-color: #D1EEEE; margin-bottom: 30px;">
						<div class="top_el">
							基本信息
						</div>
						<el-form>
							<table class="tlist_el" style="width: 100%;" bgcolor="#D1EEEE" cellpadding="5px">
								<tbody>
									<tr>
										<td class="ltd_el">流水号：</td>
										<td class="ltd_tag">{{ vstudentpotential.id }}</td>
										<td class="ltd_el">姓名：</td>
										<td class="ltd_tag">{{ vstudentpotential.name }}</td>
										<td class="ltd_el">性别：</td>
										<td class="ltd_tag">{{ vstudentpotential.sexval }}</td>
										<td class="ltd_el">固定电话：</td>
										<td class="ltd_tag">{{ vstudentpotential.phone }}</td>
									</tr>
									<tr>
										<td class="ltd_el">手机：</td>
										<td class="ltd_tag">
											{{ vstudentpotential.mobile | phoneFilter()}}
										</td>
										<td class="ltd_el">手机2：</td>
										<td class="ltd_tag">{{ vstudentpotential.mobile2 }}</td>
										<td class="ltd_el"> 微信：</td>
										<td class="ltd_tag">
											{{ vstudentpotential.weixin }}
										</td>
										<td class="ltd_el">QQ：</td>
										<td class="ltd_tag">{{ vstudentpotential.qq }}</td>
									</tr>
									<tr>
										<td class="ltd_el">项目类型：</td>
										<td class="ltd_tag">{{ vstudentpotential.projecttypeval }}</td>
										<td class="ltd_el">户口所在地：</td>
										<td class="ltd_tag">{{ vstudentpotential.hukouaddr }}</td>
										<td class="ltd_el">政治面貌：</td>
										<td class="ltd_tag">{{ vstudentpotential.polival }}</td>
										<td class="ltd_el">民族：</td>
										<td class="ltd_tag">{{ vstudentpotential.nationval }}</td>
									</tr>
									<tr>
										<td class="ltd_el">出生日期：</td>
										<td class="ltd_tag">{{ vstudentpotential.birthday | formatDate("YYYY-MM-DD")}}
										</td>
										<td class="ltd_el">籍贯：</td>
										<td class="ltd_tag">{{ vstudentpotential.comefrom }}</td>
										<td class="ltd_el">证件号码：</td>
										<td class="ltd_tag">{{ vstudentpotential.idcard }}</td>
										<td class="ltd_el">婚否：</td>
										<td class="ltd_tag">{{ vstudentpotential.marryval }}</td>
									</tr>
									<tr>
										<td class="ltd_el">操作人：</td>
										<td class="ltd_tag">{{ vstudentpotential.opusername }}</td>
										<td class="ltd_el"> 录入时间：</td>
										<td class="ltd_tag">
											{{
										  vstudentpotential.inserttime
										    | formatDate("YYYY-MM-DD HH:mm:ss")
										}}
										</td>
										<td class="ltd_el">意向程度：</td>
										<td class="ltd_tag">{{ vstudentpotential.intentionval }}</td>
										<td class="ltd_el">意向专业：</td>
										<td class="ltd_tag">{{ vstudentpotential.wantcollegemajor }}</td>
									</tr>
									<tr>
										<td class="ltd_el">层次：</td>
										<td class="ltd_tag">{{ vstudentpotential.levelval }}</td>
										<td class="ltd_el">住址：</td>
										<td class="ltd_tag">{{ vstudentpotential.homeaddr }}</td>
										<td class="ltd_el">正式学生：</td>
										<td class="ltd_tag">{{ available[vstudentpotential.studentflag] }}</td>
										<td class="ltd_el"> 最后操作时间：</td>
										<td class="ltd_tag">
											{{
										    vstudentpotential.lastinserttime
										      | formatDate("YYYY-MM-DD HH:mm:ss")
										  }}
										</td>
									</tr>
									<tr>
										<td class="ltd_el">毕业院校：</td>
										<td class="ltd_tag">{{ vstudentpotential.graduateschool }}</td>
										<td class="ltd_el">毕业时间：</td>
										<td class="ltd_tag">
											{{
									      vstudentpotential.graduatetime
									        | formatDate("YYYY-MM-DD")
									    }}
										</td>
										<td class="ltd_el">所属分公司：</td>
										<td class="ltd_tag">{{ vstudentpotential.companyorgname }}</td>
										<td class="ltd_el">所属教学点：</td>
										<td class="ltd_tag">{{ vstudentpotential.schoolorgname }}</td>
									</tr>
									<tr>
										<td colspan="9" class="top_el">报读意向</td>
									</tr>
									<tr>
										<td class="ltd_el">邮编：</td>
										<td class="ltd_tag">{{ vstudentpotential.homezipcode }}</td>
										<td class="ltd_el">项目类型：</td>
										<td class="ltd_tag">{{ vstudentpotential.projecttypeval }}</td>
										<td class="ltd_el">层次：</td>
										<td class="ltd_tag">{{ vstudentpotential.levelval }}</td>
										<td class="ltd_el">生源来源：</td>
										<td class="ltd_tag">{{ vstudentpotential.fromtypeval }}</td>
									</tr>
									<tr>
										<td class="ltd_el">跟进人：</td>
										<td class="ltd_tag">{{ vstudentpotential.followusername }}</td>
										<td class="ltd_el"> 转介绍人：</td>
										<td class="ltd_tag">
											{{ vstudentpotential.linkman }}
										</td>
										<td class="ltd_el">合作企业人：</td>
										<td class="ltd_tag">{{ vstudentpotential.enterprisename }}</td>
										<td class="ltd_el">客服：</td>
										<td class="ltd_tag">{{ vstudentpotential.kefuusername }}</td>
									</tr>
									<tr>
										<td class="ltd_el">跟进次数：</td>
										<td class="ltd_tag">{{ vstudentpotential.followcount }}</td>
										<td class="ltd_el"></td>
										<td class="ltd_tag"></td>
									</tr>
									<tr>
										<td colspan="9" class="top_el">个人简历</td>
									</tr>
									<tr>
										<td class="ltd_el">学历：</td>
										<td class="ltd_tag">{{ vstudentpotential.eduval }}</td>
										<td class="ltd_el">职业：</td>
										<td class="ltd_tag">{{ vstudentpotential.job }}</td>
										<td class="ltd_el">毕业院校：</td>
										<td class="ltd_tag">{{ vstudentpotential.graduateschool }}</td>
										<td class="ltd_el">专科毕业专业：</td>
										<td class="ltd_tag">{{ vstudentpotential.zhuankemajor }}</td>
									</tr>
									<tr>
										<td class="ltd_el">专科毕业证书号：</td>
										<td class="ltd_tag">{{ vstudentpotential.zhuankeno }}</td>
										<td class="ltd_el">毕业时间</td>
										<td class="ltd_tag">
											{{
											vstudentpotential.graduatetime | formatDate("YYYY-MM-DD")
											}}
										</td>
										<td class="ltd_el">工作单位：</td>
										<td class="ltd_tag">{{ vstudentpotential.companyname }}</td>
										<td class="ltd_el">工作类型：</td>
										<td class="ltd_tag">
											{{ vstudentpotential.jobtype }}
										</td>
									</tr>
									<tr>
										<td class="ltd_el"> 参加工作时间：</td>
										<td class="ltd-tag">
											{{
												vstudentpotential.jobtime | formatDate("YYYY-MM-DD")
											}}
										</td>
										<td class="ltd_el"> 住址：</td>
										<td class="ltd-tag" colspan="3">
											{{ vstudentpotential.homeaddr }}
										</td>
										<td class="ltd_el">邮编：</td>
										<td class="ltd-tag">
											{{ vstudentpotential.homezipcode }}
										</td>
									</tr>
								</tbody>
							</table>
						</el-form>
					</div>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 1">
					<!-- 学历报名记录 -->
					<el-table
						:data="showDetailList.studentlist"
						border
						:key="key"
						stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}">
						<el-table-column label="流水号" prop="id" align="center"></el-table-column>
						<el-table-column label="姓名" prop="qianzainame" align="center" />
						<el-table-column label="考生号" prop="studentcode" align="center"></el-table-column>
						<el-table-column label="年级" prop="grade" align="center"></el-table-column>
						<el-table-column label="院校" prop="collegename1" align="center"></el-table-column>
						<el-table-column label="专业" prop="majorname1" align="center"></el-table-column>
						<el-table-column label="层次" prop="levelval" align="center"></el-table-column>
						<el-table-column label="应收" prop="shouldrecvmoney" align="center">
						</el-table-column>
						<el-table-column label="实收" prop="realrecvmoney" align="center">
						</el-table-column>
						<el-table-column label="退费" prop="feebackmoney" align="center">
						</el-table-column>
						<el-table-column label="收据编号" prop="shoujucode" align="center">
						</el-table-column>
						<el-table-column label="项目类型" prop="projecttype" align="center">
						</el-table-column>
						<el-table-column label="教学点" prop="schoolorgname" align="center">
						</el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 2">
					<!-- '收费记录' -->
					<el-table
						:data="showDetailList.studentmoneyrecvlist"
						border
						stripe
						:key="key"
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}">
						<el-table-column label="流水号" prop="id" align="center" />
						<el-table-column label="姓名" prop="studentname" align="center" />
						<el-table-column label="身份证" prop="idcard" align="center" />
						<el-table-column label="金额" prop="recvmoney" align="center" />
						<el-table-column label="缴费时间" align="center" width="160">
							<template slot-scope="scope">
								{{ scope.row.recvtime | formatDate("YYYY-MM-DD HH:mm:ss") }}
							</template>
						</el-table-column>
						<el-table-column label="收据编号" prop="shoujucode" align="center" />
						<el-table-column label="经手人" prop="insertusername" align="center" />
						<el-table-column label="冲账" align="center">
							<template slot-scope="scope">
								{{ chongzhanflag[scope.row.invertflag] }}
							</template>
						</el-table-column>
						<el-table-column label="教学点" prop="schoolorgname" align="center" />
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 3">
					<!-- '培训记录' -->
					<el-table
						:data="showDetailList.techfudaolist"
						border
						:key="key"
						stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}">
						<el-table-column label="流水号" prop="id" align="center"></el-table-column>
						<el-table-column label="姓名" prop="studentname" align="center">
						</el-table-column>
						<el-table-column label="班别" prop="fudaoclasstypename" align="center"></el-table-column>

						<el-table-column label="报读时间" align="center" width="160">
							<template slot-scope="scope">
								{{ scope.row.regdate | formatDate("YYYY-MM-DD") }}
							</template>
						</el-table-column>

						<el-table-column label="应收" prop="totalmoney" align="center">
						</el-table-column>
						<el-table-column label="减免金额" prop="extrasubmoney" align="center">
						</el-table-column>

						<el-table-column label="实收" prop="recvmoney" align="center">
						</el-table-column>
						<el-table-column label="退费金额" prop="feebackmoney" align="center">
						</el-table-column>
						<el-table-column label="收据编号" prop="shoujucode" align="center">
						</el-table-column>
						<el-table-column label="教学点" prop="schoolorgname" align="center">
						</el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 4">
					<!-- ,'学籍记录 ' -->
					<el-table
						:data="showDetailList.studentregisterlist"
						border
						:key="key"
						stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}">
						<el-table-column label="流水号" prop="id" align="center"></el-table-column>
						<el-table-column label="姓名" prop="studentname" align="center">
						</el-table-column>
						<el-table-column label="年级" prop="grade" align="center">
						</el-table-column>
						<el-table-column label="院校" prop="collegename" align="center">
						</el-table-column>
						<el-table-column label="专业" prop="majorname" align="center">
						</el-table-column>
						<el-table-column label="层次" prop="levelval" align="center">
						</el-table-column>
						<el-table-column label="学年" prop="iyear" align="center">
						</el-table-column>
						<el-table-column label="项目类型" prop="projecttype" align="center">
						</el-table-column>
						<el-table-column label="教学点" prop="schoolorgname" align="center">
						</el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 5">
					<!-- ,'跟进记录' -->
					<el-table
						:data="showDetailList.studentgenzonglist"
						border
						stripe
						:key="key"
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}">
						<el-table-column label="流水号" prop="id" align="center" />
						<el-table-column label="学生" prop="qianzainame" align="center" />
						<el-table-column label="跟进内容" prop="followcontent" align="center" />
						<el-table-column label="跟进时间" align="center" width="160">
							<template slot-scope="scope">
								{{ scope.row.inserttime | formatDate("YYYY-MM-DD HH:mm:ss") }}
							</template>
						</el-table-column>
						<el-table-column label="跟进人" prop="username" align="center" />
						<el-table-column label="教学点" prop="schoolorgname" align="center" />
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 6">
					<el-table :data="showDetailList.studentfudaolist" border stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}">
						<el-table-column label="流水号" prop="id" align="center"></el-table-column>
						<el-table-column label="姓名" prop="studentname" align="center">
						</el-table-column>
						<el-table-column label="年级" prop="grade" align="center"></el-table-column>

						<el-table-column label="辅导班" prop="fudaoclasstypename" align="center">
						</el-table-column>

						<el-table-column label="应收" prop="totalmoney" align="center">
						</el-table-column>

						<el-table-column label="实收" prop="recvmoney" align="center">
						</el-table-column>

						<el-table-column label="优惠" prop="preferentialmoney" align="center">
						</el-table-column>
						<el-table-column label="额外减免" prop="extrasubmoney" align="center">
						</el-table-column>

						<el-table-column label="退费" prop="feebackmoney" align="center">
						</el-table-column>
						<el-table-column label="收据编号" prop="shoujucode" align="center">
						</el-table-column>
						<el-table-column label="教学点" prop="schoolorgname" align="center">
						</el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 7">
					<el-table :data="showDetailList.studentyubaominglist" border stripe
						:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}">
						<el-table-column label="流水号" prop="id" align="center"></el-table-column>
						<el-table-column label="姓名" prop="qianzainame" align="center">
						</el-table-column>
						<el-table-column label="年级" prop="year" align="center"></el-table-column>
						<el-table-column label="应收" prop="totalmoney" align="center">
						</el-table-column>
						<el-table-column label="实收" prop="recvmoney" align="center">
						</el-table-column>
						<el-table-column label="退费" prop="feebackmoney" align="center">
						</el-table-column>
						<el-table-column label="收据号" prop="shoujucode" align="center">
						</el-table-column>
						<el-table-column label="教学点" prop="schoolorgname" align="center">
						</el-table-column>
					</el-table>
				</div>
				<div class="OrderContentBox" v-show="currentTab === 8">
					<div class="demo-image__preview demo-image__error">
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">身份证正面</p>
							<div class="image_pic_el">
								<el-image class="adaptation"
									:src="vstudentpotential.idphoto ? $url.upload + vstudentpotential.idphoto : ''"
									@click="vbs(vstudentpotential.idphoto ? $url.upload + vstudentpotential.idphoto : '')"
									:preview-src-list="srcList" :z-index="99999">
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">身份证反面</p>
							<div class="image_pic_el">
								<el-image class="adaptation"
									:src="vstudentpotential.idphoto2 ? $url.upload + vstudentpotential.idphoto2 : ''"
									@click="vbs(vstudentpotential.idphoto2 ? $url.upload + vstudentpotential.idphoto2 : '')"
									:preview-src-list="srcList" :z-index="99999">
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">照片</p>
							<div class="image_pic_el">
								<el-image class="adaptation"
									:src=" vstudentpotential.photo ? $url.upload + vstudentpotential.photo : ''"
									@click="vbs(vstudentpotential.photo ? $url.upload + vstudentpotential.photo : '')"
									:preview-src-list="srcList" :z-index="99999">
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">户口本首页</p>
							<div class="image_pic_el">
								<el-image class="adaptation"
									:src=" vstudentpotential.registerphoto ? $url.upload + vstudentpotential.registerphoto : ''"
									@click="vbs(vstudentpotential.registerphoto ? $url.upload + vstudentpotential.registerphoto : '')"
									:preview-src-list="srcList" :z-index="99999">
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">户口本人页</p>
							<div class="image_pic_el">
								<el-image class="adaptation"
									:src=" vstudentpotential.registerphoto2 ? $url.upload + vstudentpotential.registerphoto2 : ''"
									@click="vbs(vstudentpotential.registerphoto2 ? $url.upload + vstudentpotential.registerphoto2 : '')"
									:preview-src-list="srcList" :z-index="99999">
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">毕业证</p>
							<div class="image_pic_el">
								<el-image class="adaptation"
									:src="vstudentpotential.schoolphoto ? $url.upload + vstudentpotential.schoolphoto : ''"
									@click="vbs(vstudentpotential.schoolphoto ? $url.upload + vstudentpotential.schoolphoto : '')"
									:preview-src-list="srcList" :z-index="99999">
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">注册备案表</p>
							<div class="image_pic_el">
								<el-image class="adaptation"
									:src=" vstudentpotential.regphoto ? $url.upload + vstudentpotential.regphoto : ''"
									@click="vbs(vstudentpotential.regphoto ? $url.upload + vstudentpotential.regphoto : '')"
									:preview-src-list="srcList" :z-index="99999">
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
						<el-card shadow="hover" class="el-card_image_next">
							<p class="demonstration text-align text-blue">居住证明</p>
							<div class="image_pic_el">
								<el-image class="adaptation"
									:src=" vstudentpotential.residence ? $url.upload + vstudentpotential.residence : ''"
									@click="vbs(vstudentpotential.residence ? $url.upload + vstudentpotential.residence : '')"
									:preview-src-list="srcList" :z-index="99999">
									<div slot="error" class="image-slot el-image__error">
										<i class="el-icon-picture-outline"></i>
										<span>暂无图片</span>
									</div>
								</el-image>
							</div>
						</el-card>
					</div>
				</div>
			</div>
		</div>
		<!--修改-->
		<el-dialog class="btn-2b5a95" width="1300px" v-dialogDrag title="修改：" :close-on-click-modal='false'
			:visible.sync="showAddModal" v-if="showAddModal" append-to-body>
			<el-form :model="dynamicValidateForm" ref="dynamicValidateForm" label-width="120px"
				class="demo-dynamic form-item-w-33" status-icon :rules="rules">
				<div class="title">基本信息</div>
				<el-form-item prop="name" label="姓名:">
					<el-input v-model="dynamicValidateForm.name" disabled />
				</el-form-item>
				<el-form-item label="性别:">
					<el-select v-model="dynamicValidateForm.sexval"
						@change="(val, prop) => onChangeSelect(val, 'sexkey')" clearable>
						<el-option value="1" label="男"></el-option>
						<el-option value="2" label="女"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="民族:" prop="nationkey">
					<el-select v-model="dynamicValidateForm.nationkey" clearable>
						<el-option v-for="(item, index) in nationList" :key="index" :label="item.val"
							:value="item.key" />
					</el-select>
				</el-form-item>
				<el-form-item label="出生日期:" prop="birthday">
					<el-date-picker type="date" v-model="dynamicValidateForm.birthday" placeholder="请选择" clearable />
				</el-form-item>
				<el-form-item label="政治面貌:" prop="polikey">
					<el-select v-model="dynamicValidateForm.polikey" clearable>
						<el-option v-for="item in policyList" :key="item" :value="item.key" :label="item.val" />
					</el-select>
				</el-form-item>
				<el-form-item prop="nowaddr" label=" 通讯地址：">
					<el-input v-model.trim="dynamicValidateForm.nowaddr" clearable />
				</el-form-item>
				<el-form-item label="家庭住址:" prop="homeaddr">
					<el-input v-model.trim="dynamicValidateForm.homeaddr" clearable />
				</el-form-item>
				<el-form-item prop="registeredSubjects" label="报考科类：">
					<el-select v-model="dynamicValidateForm.registeredsubjects" @change="registereChange" clearable>
						<el-option v-for="(obj, index) in baokaokeleiList" :key="index" :label="obj.val"
							:value="obj.val" clearable />
					</el-select>
				</el-form-item>
				<el-form-item label="考前学历" prop="eduval">
					<el-select v-model="dynamicValidateForm.eduval" clearable>
						<el-option v-for="(edualForm, index) in preExaminationList" :key="index" :label="edualForm.val"
							:value="edualForm.val" clearable />
					</el-select>
				</el-form-item>
				<el-form-item prop="hukouaddr" label="户口所在地">
					<el-select v-model="dynamicValidateForm.hukouaddr" clearable>
						<el-option v-for="(hukou, index) in registeredResidenceList" :key="index" :label="hukou.val"
							:value="hukou.val" clearable />
					</el-select>
				</el-form-item>
				<el-form-item prop="graduateschool" label="毕业院校">
					<el-input v-model.trim="dynamicValidateForm.graduateschool" clearable />
				</el-form-item>
				<el-form-item label="固定电话：" prop="phone">
					<el-input v-model.trim="dynamicValidateForm.phone" clearable />
				</el-form-item>
				<el-form-item prop="zhuankemajor" label="毕业专业">
					<el-input v-model.trim="dynamicValidateForm.zhuankemajor" clearable />
				</el-form-item>
				<el-form-item prop="course" label="考试科目组：">
					<el-select v-model="dynamicValidateForm.course" @change="changeCourse" clearable>
						<el-option v-for="(item, index) in typeOptions" :key="index" :label="item" :value="item"
							clearable />
					</el-select>
				</el-form-item>
				<el-form-item label="照顾加分：">
					<el-checkbox v-model="dynamicValidateForm.points" true-label="1" false-label="0">
						是
					</el-checkbox>
				</el-form-item>
				<el-form-item label="考试语种:">
					<el-select v-model="dynamicValidateForm.languagecode" clearable>
						<el-option v-for="(code,index) in languageTypeList" :key="index" :value="code.val"
							:label="code.val" clearable />
					</el-select>
				</el-form-item>
				<el-form-item prop="zhuankeno" label="毕业证号">
					<el-input v-model.trim="dynamicValidateForm.zhuankeno" clearable />
				</el-form-item>
				<el-form-item prop="graduatetime" label="毕业年月日">
					<el-date-picker type="date" v-model="dynamicValidateForm.graduatetime" @input="$forceUpdate()"
						placeholder="请选择" clearable />
				</el-form-item>
				<el-form-item label="入伍时间:" prop="joinuptime">
					<el-date-picker type="date" v-model="dynamicValidateForm.joinuptime" @input="$forceUpdate()"
						placeholder="请选择" clearable />
				</el-form-item>
				<el-form-item label="退伍时间:" prop="joinouttime">
					<el-date-picker type="date" v-model="dynamicValidateForm.joinouttime" @input="$forceUpdate()"
						placeholder="请选择" clearable />
				</el-form-item>
				<el-form-item label="退役号:">
					<el-input v-model.trim="dynamicValidateForm.retirementnumber" clearable />
				</el-form-item>
				<el-form-item prop="job" label="职业">
					<el-select v-model="dynamicValidateForm.job" clearable>
						<el-option v-for="(objob, index) in occupationList" :key="objob.index" :label="objob.val"
							:value="objob.val" clearable />
					</el-select>
				</el-form-item>
				<el-form-item label="证件号:" prop="idcard">
					<el-input v-model.trim="dynamicValidateForm.idcard" @blur="getIdcard(dynamicValidateForm.idcard)"
						clearable />
				</el-form-item>
				<el-form-item prop="homezipcode" label="邮政编码">
					<el-input v-model.trim="dynamicValidateForm.homezipcode" clearable />
				</el-form-item>
				<el-form-item label="报名点：">
					<el-select v-model="dynamicValidateForm.registrationpointval" clearable>
						<el-option v-for="(registraval, index) in registrationPointList" :key="registraval.index"
							:label="registraval.val" :value="registraval.val" clearable />
					</el-select>
				</el-form-item>
				<el-form-item label="考试县区:" prop="examination">
					<el-select v-model="dynamicValidateForm.examination" clearable>
						<el-option v-for="(areaflag, index) in areaflags" :key="areaflag.index" :label="areaflag.label"
							:value="areaflag.label" clearable />
					</el-select>
				</el-form-item>
				<el-form-item label="预报名号:">
					<el-input v-model.trim="dynamicValidateForm.yubaocode" clearable />
				</el-form-item>
				<el-form-item label="考生号:">
					<el-input v-model.trim="dynamicValidateForm.studentcode" clearable />
				</el-form-item>
				<div class="title">报考志愿</div>
				<el-form-item prop="projecttype" label="项目类型:"
					:rules="[{ required: true, message: '必填字段', trigger: 'change' }]">
					<el-select v-model="dynamicValidateForm.projecttype"
						@change="onChangeprojecttype($event, 'dynamicValidateForm')" clearable>
						<el-option v-for="item in educationList" :key="item.key" :label="item.val" :value="item.val">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="levelval" label="层次：">
					<el-select v-model="dynamicValidateForm.levelval" clearable @change="changeSelect">
						<el-option v-for="(item, index) in arr_province" :key="index" :label="item" :value="item"
							clearable />
					</el-select>
				</el-form-item>
				<el-form-item prop="studytypeval" label="学习形式:"
					:rules="[{ required: true, message: '必填字段', trigger: 'change' }]">
					<el-select v-model="dynamicValidateForm.studytypeval" @change="onChangestudytypeval" clearable>
						<el-option v-for="item in studyTypeList" :key="item.key" :label="item.val" :value="item">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="招生年份:" prop="year">
					<el-date-picker type="year" value-format="yyyy" format="yyyy" v-model="dynamicValidateForm.year"
						@change="ChangeYear" placeholder="请选择年份" />
				</el-form-item>
				<el-form-item prop="grade" label="招生年级:">
					<el-input disabled v-model="dynamicValidateForm.grade" />
				</el-form-item>
				<el-form-item prop="collegename1" label="报考院校1:">
					<el-input disabled v-model="dynamicValidateForm.collegename1" />
				</el-form-item>
				<el-form-item label="院校代码1:">
					<el-input v-model.trim="dynamicValidateForm.collegenamecode1" clearable />
				</el-form-item>
				<el-form-item label="专业代码1:">
					<el-input v-model.trim="dynamicValidateForm.majornamecode1" clearable />
				</el-form-item>
				<el-form-item prop="majorname1" label="报考专业1:">
					<el-input disabled v-model="dynamicValidateForm.majorname1" />
					<el-button type="primary" class="select-btn" @click="
              reportSelectBtn(
                collegefeestandard_dialogresult,
                'showCollegemajorModal',
                {
                  schoolorgid: dynamicValidateForm.schoolorgid,
                  enrollyearval: dynamicValidateForm.year,
                  majorlevelval: dynamicValidateForm.levelval,
                  projectname: dynamicValidateForm.projecttype,
									studytypeval: dynamicValidateForm.studytypeval,
                }
              )
            ">选择</el-button>
				</el-form-item>
				<el-form-item label="报考院校2:">
					<el-input v-model.trim="dynamicValidateForm.collegename2" clearable />
				</el-form-item>
				<el-form-item label="院校代码2:">
					<el-input v-model.trim="dynamicValidateForm.collegenamecode2" clearable />
				</el-form-item>
				<el-form-item label="专业代码2:">
					<el-input v-model.trim="dynamicValidateForm.majornamecode2" clearable />
				</el-form-item>
				<el-form-item label="报考专业2:">
					<el-input v-model.trim="dynamicValidateForm.majorname21" clearable />
				</el-form-item>

				<el-form-item label="第一志愿函授站:" prop="guakaoschoolorgname">
					<el-input v-model="dynamicValidateForm.guakaoschoolorgname" disabled />
					<el-button type="primary" class="select-btn" @click="
              onCollegecoporgSelectData(
                collegecoporg_dialogresult,
                'showCollegecoporg',
                {
                  collegename: dynamicValidateForm.collegename1,
                }
              )
            ">选择</el-button>
				</el-form-item>
				<el-form-item label="函授站:" prop="guakaocompanyorgname">
					<el-input v-model="dynamicValidateForm.guakaocompanyorgname" disabled />
				</el-form-item>
				<el-form-item label="第一志愿上课点:" prop="classaddress">
					<el-input v-model="dynamicValidateForm.classaddress" disabled />
				</el-form-item>

				<div class="title">本人简历</div>
				<div v-for="(item, i) in dynamicValidateForm.moreItems1" :key="item.id"
					class="more-form-item-w box-w-33">
					<el-form-item label="起止时间:" :prop="'moreItems1.' + i + '.duration'">
						<el-input v-model.trim="item.duration" clearable></el-input>
					</el-form-item>
					<el-form-item label-width="135px" label="学习及工作经历：" :prop="'moreItems1.' + i + '.appoint'">
						<el-input type="textarea" v-model.trim="item.appoint" clearable></el-input>
					</el-form-item>
					<el-form-item label="职务：" :prop="'moreItems1.' + i + '.post'">
						<el-input v-model.trim="item.post" clearable></el-input>
					</el-form-item>
					<el-form-item label="学制：" :prop="'moreItems1.' + i + '.xuezhival'">
						<el-input v-model.trim="item.xuezhival" clearable></el-input>
					</el-form-item>
					<el-form-item label="工作年限：" :prop="'moreItems1.' + i + '.workingyears'">
						<el-input v-model.trim="item.workingyears" clearable></el-input>
					</el-form-item>
					<el-form-item label="毕业/肄业：" :prop="'moreItems1.' + i + '.graduation'">
						<el-input v-model.trim="item.graduation" clearable></el-input>
					</el-form-item>
					<el-button @click="report_onRemoveMoreForm(i, 'moreItems1')" style="margin: 0 10px; height: 40px">删除
					</el-button>
				</div>
				<el-button @click="report_addFormItem1" style="margin: 10px">添加一行</el-button>

				<div class="title">家庭成员</div>
				<div v-for="(item, i) in dynamicValidateForm.moreItems2" :key="item.id"
					class="more-form-item-w box-w-25">
					<el-form-item label="姓名：" :prop="'moreItems2.' + i + '.resumename'">
						<el-input v-model.trim="item.resumename" clearable></el-input>
					</el-form-item>
					<el-form-item label="称谓：" :prop="'moreItems2.' + i + '.appellation'">
						<el-input v-model.trim="item.appellation" clearable></el-input>
					</el-form-item>
					<el-form-item label="年龄：" :prop="'moreItems2.' + i + '.age'">
						<el-input v-model.trim="item.age" clearable></el-input>
					</el-form-item>
					<el-form-item label="职业：" :prop="'moreItems2.' + i + '.occupation'">
						<el-input v-model.trim="item.occupation" clearable></el-input>
					</el-form-item>
					<el-button @click="report_onRemoveMoreForm(i, 'moreItems2')" style="margin: 0 10px; height: 40px">删除
					</el-button>
				</div>
				<el-button @click="report_addFormItem2" style="margin: 10px">添加一行</el-button>

				<div class="title">资料上传</div>
				<el-form-item prop="idphoto" label="身份证正面:">
					<el-image style="width: 150px; height: 150px;object-fit: contain;"
						:src="dynamicValidateForm.idphoto ? $url.upload + dynamicValidateForm.idphoto : ''">
						<div slot="error" class="image-slot el-image__error">
							<i class="el-icon-picture-outline"></i>
							<span>暂无图片</span>
						</div>
					</el-image>
					<el-upload class="upload-demo" :action="$store.state.global.baseUrl" :before-upload="beforeUpload"
						:http-request="() => uploadFile('idphoto')" multiple :show-file-list="false">
						<el-button type="primary" class="select-btn">点击上传</el-button>
						<div slot="tip" class="el-upload__tip"></div>
					</el-upload>
				</el-form-item>
				<el-form-item prop="idphoto2" label="身份证反面:">
					<el-image style="width: 150px; height: 150px;object-fit: contain;"
						:src="dynamicValidateForm.idphoto2 ? $url.upload + dynamicValidateForm.idphoto2 : ''">
						<div slot="error" class="image-slot el-image__error">
							<i class="el-icon-picture-outline"></i>
							<span>暂无图片</span>
						</div>
					</el-image>
					<el-upload class="upload-demo" :action="$store.state.global.baseUrl" multiple
						:before-upload="beforeUpload" :http-request="() => uploadFile('idphoto2')"
						:show-file-list="false">
						<el-button type="primary" class="select-btn">点击上传</el-button>
						<div slot="tip" class="el-upload__tip"></div>
					</el-upload>
				</el-form-item>
				<el-form-item prop="schoolphoto" label="毕业证:">
					<el-image style="width: 150px; height: 150px;object-fit: contain;"
						:src="dynamicValidateForm.schoolphoto ? $url.upload + dynamicValidateForm.schoolphoto : ''">
						<div slot="error" class="image-slot el-image__error">
							<i class="el-icon-picture-outline"></i>
							<span>暂无图片</span>
						</div>
					</el-image>
					<el-upload class="upload-demo" :action="$store.state.global.baseUrl" multiple
						:before-upload="beforeUpload" :http-request="() => uploadFile('schoolphoto')"
						:show-file-list="false">
						<el-button type="primary" class="select-btn">点击上传</el-button>
						<div slot="tip" class="el-upload__tip"></div>
					</el-upload>
				</el-form-item>
				<el-form-item prop="registerphoto" label="户口本首页:">
					<el-image style="width: 150px; height: 150px;object-fit: contain;"
						:src="dynamicValidateForm.registerphoto ? $url.upload + dynamicValidateForm.registerphoto : ''">
						<div slot="error" class="image-slot el-image__error">
							<i class="el-icon-picture-outline"></i>
							<span>暂无图片</span>
						</div>
					</el-image>
					<el-upload class="upload-demo" :action="$store.state.global.baseUrl" multiple
						:before-upload="beforeUpload" :http-request="() => uploadFile('registerphoto')"
						:show-file-list="false">
						<el-button type="primary" class="select-btn">点击上传</el-button>
						<div slot="tip" class="el-upload__tip"></div>
					</el-upload>
				</el-form-item>
				<el-form-item prop="registerphoto2" label="户口本人页面:">
					<el-image style="width: 150px; height: 150px;object-fit: contain;"
						:src="dynamicValidateForm.registerphoto2 ? $url.upload + dynamicValidateForm.registerphoto2 : ''">
						<div slot="error" class="image-slot el-image__error">
							<i class="el-icon-picture-outline"></i>
							<span>暂无图片</span>
						</div>
					</el-image>
					<el-upload class="upload-demo" :action="$store.state.global.baseUrl" multiple
						:before-upload="beforeUpload" :http-request="() => uploadFile('registerphoto2')"
						:show-file-list="false">
						<el-button type="primary" class="select-btn">点击上传</el-button>
						<div slot="tip" class="el-upload__tip"></div>
					</el-upload>
				</el-form-item>
				<el-form-item prop="photo" label="照片:">
					<el-image style="width: 150px; height: 150px;object-fit: contain;"
						:src="dynamicValidateForm.photo ? $url.upload + dynamicValidateForm.photo : ''">
						<div slot="error" class="image-slot el-image__error">
							<i class="el-icon-picture-outline"></i>
							<span>暂无图片</span>
						</div>
					</el-image>
					<el-upload class="upload-demo" :action="$store.state.global.baseUrl" multiple
						:before-upload="beforeUpload" :http-request="() => uploadFile('photo')" :show-file-list="false">
						<el-button type="primary" class="select-btn">点击上传</el-button>
						<div slot="tip" class="el-upload__tip"></div>
					</el-upload>
				</el-form-item>
				<el-form-item prop="regphoto" label="注册备案表:">
					<el-image style="width: 150px; height: 150px;object-fit: contain;"
						:src="dynamicValidateForm.regphoto ? $url.upload + dynamicValidateForm.regphoto : ''">
						<div slot="error" class="image-slot el-image__error">
							<i class="el-icon-picture-outline"></i>
							<span>暂无图片</span>
						</div>
					</el-image>
					<el-upload class="upload-demo" :action="$store.state.global.baseUrl" multiple
						:before-upload="beforeUpload" :http-request="() => uploadFile('regphoto')"
						:show-file-list="false">
						<el-button type="primary" class="select-btn">点击上传</el-button>
						<div slot="tip" class="el-upload__tip"></div>
					</el-upload>
				</el-form-item>
				<el-form-item prop="residence" label="居住证明:">
					<el-image style="width: 150px; height: 150px;object-fit: contain;"
						:src="dynamicValidateForm.residence ? $url.upload + dynamicValidateForm.residence : ''">
						<div slot="error" class="image-slot el-image__error">
							<i class="el-icon-picture-outline"></i>
							<span>暂无图片</span>
						</div>
					</el-image>
					<el-upload class="upload-demo" :action="$store.state.global.baseUrl" multiple
						:before-upload="beforeUpload" :http-request="() => uploadFile('residence')"
						:show-file-list="false">
						<el-button type="primary" class="select-btn">点击上传</el-button>
						<div slot="tip" class="el-upload__tip"></div>
					</el-upload>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="onSubmitAddOrEditForm('dynamicValidateForm')">提交
				</el-button>
			</span>
		</el-dialog>

		<!--选择层次-->
		<el-dialog class="btn-2b5a95" width="50%" v-dialogDrag :close-on-click-modal='false' @close="onCloseSelect"
			title="选择院校专业：" :visible.sync="showSelectCollegeModal" v-if="showSelectCollegeModal" append-to-body>
			<el-button type="primary" @click="onConfirmCollegeMajor">确定</el-button>

			<div class="index_2 btn-2b5a95">
				<div class="l-w" :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }">
					<el-form label-position="right" :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            " :model="selectSearchForm">
						<el-form-item label="院校名称:" prop="collegename">
							<el-input v-model="selectSearchForm.collegename" clearable />
						</el-form-item>
						<el-form-item label="专业名称:" prop="majorname">
							<el-input v-model="selectSearchForm.majorname" clearable />
						</el-form-item>
					</el-form>
				</div>
				<div class="r-w">
					<el-button type="primary" icon="el-icon-search" :loading="!$store.state.global.isEndRequest"
						class="btn-2b5a95" @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  { schoolorgid: dynamicValidateForm.schoolorgid },
                  selectSearchForm
                )
              )
            ">搜索</el-button>
					<el-button type="primary" :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            " v-if="$store.state.global.searchExtend_2" class="btn-2b5a95" @click="onExtendClick('isSearchExtend_2')">
						{{ $store.state.global.searchExtendText_2 }}</el-button>
				</div>
			</div>
			<el-table
				:data="selectTable.list"
				ref="moneyTable"
				border
				stripe
				:key="key"
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column label="流水号" prop="id" align="center" width="100"></el-table-column>
				<el-table-column label="招生年级" prop="enrollyearval" align="center" width="100">
				</el-table-column>
				<el-table-column label="院校" prop="collegename" align="center" width="350">
				</el-table-column>
				<el-table-column label="专业" prop="majorname" align="center" width="150">
				</el-table-column>
				<el-table-column label="层次" prop="majorlevelval" align="center" width="100">
				</el-table-column>
				<el-table-column label="所属教学点" prop="schoolorgname" align="center" width="150">
				</el-table-column>
			</el-table>
			<el-pagination background @current-change="
          (val, data) =>
            handleSelectChange(
              val,
              Object.assign(
                { schoolorgid: dynamicValidateForm.schoolorgid },
                selectSearchForm
              )
            )
        " :current-page="selectPageInfo.pageindex" :page-size="selectPageInfo.pagesize"
				:page-sizes="[10, 20, 30, 40, 50]" layout="total, prev, pager, next, jumper" :total="
          selectTable.paginationutil ? selectTable.paginationutil.totalcount : 0
        "></el-pagination>
		</el-dialog>
		<!--选择院校专业 -->
		<el-dialog class="btn-2b5a95" v-dialogDrag @close="onCloseSelect" :close-on-click-modal='false' title="选择院校专业"
			:visible.sync="showCollegemajorModal" v-if="showCollegemajorModal" width="1200px" append-to-body>
			<el-button type="primary" class="confirm" @click="reportDataComfirm">确 定</el-button>
			<div class="index_2 btn-2b5a95">
				<div class="l-w" :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }">
					<el-form label-position="right" :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            " :model="selectSearchForm" @submit.native.prevent>
						<el-form-item label="院校:" prop="collegename">
							<el-input v-model.trim="selectSearchForm.collegename" @keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign(
								    {
								      schoolorgid: dynamicValidateForm.schoolorgid,
								      enrollyearval: dynamicValidateForm.year,
								      majorlevelval: dynamicValidateForm.levelval,
								      projectname: dynamicValidateForm.projecttype,
								    },
								    selectSearchForm
								  )
								)" clearable />
						</el-form-item>
						<el-form-item label="专业:" prop="collegemajorname">
							<el-input v-model.trim="selectSearchForm.collegemajorname" @keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign(
								    {
								      schoolorgid: dynamicValidateForm.schoolorgid,
								      enrollyearval: dynamicValidateForm.year,
								      majorlevelval: dynamicValidateForm.levelval,
								      projectname: dynamicValidateForm.projecttype,
								    },
								    selectSearchForm
								  )
								)" clearable />
						</el-form-item>
						<el-form-item label="专业层次:" prop="majorlevelval">
							<el-input v-model.trim="selectSearchForm.majorlevelval" @keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  {
                    schoolorgid: dynamicValidateForm.schoolorgid,
                    enrollyearval: dynamicValidateForm.year,
                    majorlevelval: dynamicValidateForm.levelval,
                    projectname: dynamicValidateForm.projecttype,
                  },
                  selectSearchForm
                )
              )" clearable />
						</el-form-item>
					</el-form>
				</div>
				<div class="r-w">
					<el-button type="primary" icon="el-icon-search" :loading="!$store.state.global.isEndRequest"
						class="btn-2b5a95" @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  {
                    schoolorgid: dynamicValidateForm.schoolorgid,
                    enrollyearval: dynamicValidateForm.year,
                    majorlevelval: dynamicValidateForm.levelval,
                    projectname: dynamicValidateForm.projecttype,
                  },
                  selectSearchForm
                )
              )
            ">搜索</el-button>
					<el-button type="primary" @click="hanleSearchForm">重置</el-button>
					<el-button type="primary" :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            " v-if="$store.state.global.searchExtend_2" class="btn-2b5a95" @click="onExtendClick('isSearchExtend_2')">
						{{ $store.state.global.searchExtendText_2 }}</el-button>
				</div>
			</div>

			<el-table
				:data="selectTable.list"
				style="width: 100%"
				border
				:key="key"
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
				@select="handleSelectionChange">
				<el-table-column type="selection" width="55" />
				<el-table-column label="流水号" prop="id" align="center" />
				<el-table-column label="年级" prop="grade" align="center" />
				<el-table-column label="院校" prop="collegename" align="center" width="180" />
				<el-table-column label="专业" prop="collegemajorname" align="center" />
				<el-table-column label="层次" prop="majorlevelval" align="center" />
				<el-table-column label="所属教学点" prop="schoolorgname" align="center" />
			</el-table>
			<el-pagination background :current-page="selectPageInfo.pageindex" :current-size="selectPageInfo.pagesize"
				layout="total, prev, pager, next, jumper" :total="
          selectTable.paginationutil ? selectTable.paginationutil.totalcount : 0
        " @current-change="
          (val, data, prop, page) =>
            handleSelectChange(
              val,
              Object.assign(
                {
                  schoolorgid: dynamicValidateForm.schoolorgid,
                  enrollyearval: dynamicValidateForm.year,
                  majorlevelval: dynamicValidateForm.levelval,
                  projectname: dynamicValidateForm.projecttype,
                },
                selectSearchForm
              )
            )
        " />
		</el-dialog>
		<!--选择校外教学点 -->
		<el-dialog class="btn-2b5a95" @close="onCloseSelect" v-dialogDrag :close-on-click-modal='false' title="选择校外教学点"
			:visible.sync="showCollegecoporg" v-if="showCollegecoporg" width="1200px" append-to-body>
			<el-button type="primary" class="confirm" @click="onComfirmCollegecoporg">确 定</el-button>
			<div class="index_2 btn-2b5a95">
				<div class="l-w" :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }">
					<el-form label-position="right" :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            " :model="selectSearchForm" @submit.native.prevent>
						<el-form-item label="院校名称:" prop="collegename">
							<el-input v-model.trim="selectSearchForm.collegename" @keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  {
                    schoolorgid: dynamicValidateForm.schoolorgid,
                    enrollyearval: dynamicValidateForm.year,
                    majorlevelval: dynamicValidateForm.levelval,
                    projectname: dynamicValidateForm.projecttype,
                  },
                  selectSearchForm
                )
              )" clearable />
						</el-form-item>
						<el-form-item label="函授站名称:" prop="schoolorgname">
							<el-input v-model.trim="selectSearchForm.schoolorgname" @keyup.enter.native="onSelectSearchClick(
								   selectTableUrl,
								   null,
								   Object.assign(
								     {
								       schoolorgid: dynamicValidateForm.schoolorgid,
								       enrollyearval: dynamicValidateForm.year,
								       majorlevelval: dynamicValidateForm.levelval,
								       projectname: dynamicValidateForm.projecttype,
								     },
								     selectSearchForm
								   )
								 )" clearable />
						</el-form-item>
						<el-form-item label="上课地点:" prop="classname">
							<el-input v-model.trim="selectSearchForm.classname" @keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign(
								    {
								      schoolorgid: dynamicValidateForm.schoolorgid,
								      enrollyearval: dynamicValidateForm.year,
								      majorlevelval: dynamicValidateForm.levelval,
								      projectname: dynamicValidateForm.projecttype,
								    },
								    selectSearchForm
								  )
								)" clearable />
						</el-form-item>
					</el-form>
				</div>
				<div class="r-w">
					<el-button type="primary" icon="el-icon-search" :loading="!$store.state.global.isEndRequest"
						class="btn-2b5a95" @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  {
                    schoolorgid: dynamicValidateForm.schoolorgid,
                    enrollyearval: dynamicValidateForm.year,
                    majorlevelval: dynamicValidateForm.levelval,
                    projectname: dynamicValidateForm.projecttype,
                  },
                  selectSearchForm
                )
              )
            ">搜索</el-button>
					<el-button type="primary" @click="hanleSearchForm">重置</el-button>
					<el-button type="primary" :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            " v-if="$store.state.global.searchExtend_2" class="btn-2b5a95" @click="onExtendClick('isSearchExtend_2')">
						{{ $store.state.global.searchExtendText_2 }}</el-button>
				</div>
			</div>
			<el-table
				:data="selectTable.list"
				ref="multipleTable"
				border
				:key="key"
				stripe
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}" style="width: 100%"
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55" />
				<el-table-column label="流水号" prop="id" align="center" />
				<el-table-column label="院校名称" prop="collegename" align="center" />
				<el-table-column label="函授站名称" prop="schoolorgname" align="center" />
				<el-table-column label="上课点名称" prop="classname" align="center" />
			</el-table>


			<el-pagination background :current-page="selectPageInfo.pageindex" :current-size="selectPageInfo.pagesize"
				layout="total, prev, pager, next, jumper" :total="
          selectTable.totalcount
        " @current-change="
          (val, data, prop, page) =>
            handleSelectChange(
              val,
              Object.assign(
                {
                  collegename: dynamicValidateForm.collegename1,
                },
                selectSearchForm
              )
            )
        " />
		</el-dialog>

		<!-- 收款 -->
		<el-dialog
			class="btn-2b5a95"
			width="45%"
			v-dialogDrag title="收款："
			:close-on-click-modal='false'
			:visible.sync="showEnrolModal"
			v-if="showEnrolModal"
			append-to-body>
			<el-form
				:model="dynamicValidateForm"
				ref="dynamicValidateForm"
				label-width="35%"
				class="demo-dynamic form-item-w-50"
				status-icon
				:rules="rules">
				<el-form-item prop="companyorgname" label="所属分公司">
					<el-select
						v-model="dynamicValidateForm.companyorgname"
						disabled
						clearable
						@change="onChangeCompany($event, 'dynamicValidateForm')">
						<el-option v-for="item in $store.state.selectData.companyList" :key="item.key" :label="item.val"
							:value="item.key">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="schoolorgname" label="所属教学点">
					<el-select
						v-model="dynamicValidateForm.schoolorgname"
						clearable
						disabled
						@change="onChangeSchool($event, 'dynamicValidateForm')">
						<el-option
							v-for="item in $store.state.selectData.schoolList"
							:key="item.key"
							:label="item.val"
							:value="item.key">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="qianzainame2" label="姓名:">
					<el-input v-model="dynamicValidateForm.qianzainame" disabled />
				</el-form-item>
				<el-form-item prop="shoujucode" label="收据编号:">
					<el-input v-model="dynamicValidateForm.shoujucode" disabled />
					<el-button
						type="primary"
						size="small"
						class="select-btn"
						@click="getshoujuinfo">重新获取</el-button>
				</el-form-item>
				<el-form-item prop="bankorcash" label="银行/现金:">
					<el-input v-model="bankorcash[dynamicValidateForm.bankorcash]" disabled></el-input>
					<el-button type="primary" size="small" class="select-btn" @click="
              onSelectData(account_dialogresult, 'showSelectAccountModal', {
                schoolorgid: dynamicValidateForm.schoolorgid,
              })
            ">选择</el-button>
				</el-form-item>
				<el-form-item prop="accountname" label="账户名:">
					<el-input v-model="dynamicValidateForm.accountname" disabled />
				</el-form-item>
				<el-form-item prop="cardno" label="卡号:">
					<el-input v-model="dynamicValidateForm.cardno" disabled />
				</el-form-item>
				<el-form-item prop="shouldrecvmoney" label="应收金额:">
					<el-input v-model.trim="dynamicValidateForm.shouldrecvmoney" clearable />
				</el-form-item>
				<el-form-item prop="realrecvmoney" label="实收:">
					<el-input v-model.trim="dynamicValidateForm.realrecvmoney" clearable />
				</el-form-item>
				<el-form-item prop="discountfee" label="助学金(优惠):">
					<el-input v-model.trim="dynamicValidateForm.discountfee" clearable />
				</el-form-item>
				<el-form-item prop="typem2oneyintypename" label="收入二级类别:">
					<el-input v-model="dynamicValidateForm.typem2oneyintypename" disabled />
					<el-button type="primary" size="small" class="select-btn" @click="
              onSelectData(moneyintype2_dialogresult, 'showSelectTypeModal')
            ">选择</el-button>
				</el-form-item>
				<el-form-item prop="typem1oneyintypename" label="收入一级类别:">
					<el-input v-model="dynamicValidateForm.typem1oneyintypename" disabled />
				</el-form-item>
				<el-form-item prop="typemoneyintypename" label="收入类别:">
					<el-input v-model="dynamicValidateForm.typemoneyintypename" disabled />
				</el-form-item>
				<el-form-item prop="itemmoneyinitemname" label="收入明细:">
					<el-input v-model="dynamicValidateForm.itemmoneyinitemname" disabled />
					<el-button type="primary" size="small" class="select-btn" @click="
              onSelectData(moneyinitem_dialogresult, 'showSelectItemModal')
            ">选择</el-button>
				</el-form-item>
				<el-form-item prop="shoukuanrenark" label="开票说明:">
					<el-input v-model.trim="dynamicValidateForm.shoukuanrenark" clearable />
				</el-form-item>
				<el-form-item prop="discountremarks" label="优惠说明:">
					<el-input v-model.trim="dynamicValidateForm.discountremarks" clearable />
				</el-form-item>
				<el-form-item label="是否抵扣教材费" prop="isbookfee">
					<el-select v-model="dynamicValidateForm.isbookfee">
						<el-option label="是" value="1" />
						<el-option label="否" value="0" />
					</el-select>
				</el-form-item>

			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="onSubmitEnrolForm('dynamicValidateForm')">提交
				</el-button>
			</span>
		</el-dialog>

		<!-- 选择账户 -->
		<el-dialog class="btn-2b5a95" width="1300px" v-dialogDrag :close-on-click-modal='false' @close="onCloseSelect"
			title="选择账户：" :visible.sync="showSelectAccountModal" v-if="showSelectAccountModal" append-to-body>
			<el-button type="primary" @click="onConfirmAccount">确定</el-button>

			<div class="index_2 btn-2b5a95">
				<div class="l-w" :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }">
					<el-form label-position="right" :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            " :model="selectSearchForm" @suhmit.native.prevent>
						<el-form-item label="开户名称:" prop="accountname">
							<el-input v-model.trim="selectSearchForm.accountname" @keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  { schoolorgid: dynamicValidateForm.schoolorgid },
                  selectSearchForm
                )
              )" clearable />
						</el-form-item>
						<el-form-item label="银行名称:" prop="bankname">
							<el-input v-model.trim="selectSearchForm.bankname" @keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign(
								    { schoolorgid: dynamicValidateForm.schoolorgid },
								    selectSearchForm
								  )
								)" clearable />
						</el-form-item>
					</el-form>
				</div>
				<div class="r-w">
					<el-button type="primary" icon="el-icon-search" :loading="!$store.state.global.isEndRequest"
						class="btn-2b5a95" @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign(
                  { schoolorgid: dynamicValidateForm.schoolorgid },
                  selectSearchForm
                )
              )
            ">搜索</el-button>
					<el-button type="primary" @click="handleQuery">重置</el-button>
					<el-button type="primary" :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            " v-if="$store.state.global.searchExtend_2" class="btn-2b5a95" @click="onExtendClick('isSearchExtend_2')">
						{{ $store.state.global.searchExtendText_2 }}</el-button>
				</div>
			</div>

			<el-table
				:data="selectTable.list"
				style="width: 100%"
				ref="moneyTable"
				border
				stripe
				:key="key"
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55" />
				<el-table-column label="流水号" prop="id" align="center" width="200px" />
				<el-table-column label="开户名称" prop="accountname" align="center" width="250px" />
				<el-table-column label="现金/银行" align="center" width="100px">
					<template slot-scope="scope">
						{{ bankorcash[scope.row.bankorcash] }}
					</template>
				</el-table-column>
				<el-table-column label="卡号" prop="cardno" align="center" width="200px" />
				<el-table-column label="所属教学点" prop="schoolorgname" align="center" width="200px" />
			</el-table>

			<el-pagination background @current-change="
          (val, data) =>
            handleSelectChange(
              val,
              Object.assign(
                { schoolorgid: dynamicValidateForm.schoolorgid },
                selectSearchForm
              )
            )
        " :current-page="selectPageInfo.pageindex" :page-size="selectPageInfo.pagesize"
				:page-sizes="[10, 20, 30, 40, 50]" layout="total, prev, pager, next, jumper"
				:total="selectTable.totalcount"></el-pagination>
		</el-dialog>
		<!-- 选择收入二级类别 -->
		<el-dialog class="btn-2b5a95" width="77%" v-dialogDrag title="收入二级类别：" :close-on-click-modal='false'
			:visible.sync="showSelectTypeModal" v-if="showSelectTypeModal" append-to-body>
			<el-button type="primary" @click="onConfirmType">确定</el-button>

			<div class="index_2 btn-2b5a95">
				<div class="l-w" :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }">
					<el-form label-position="right" :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            " :model="selectSearchForm" @submit.native.prevent>
						<el-form-item label="收入二级项目:" prop="name">
							<el-input v-model.trim="selectSearchForm.name" @keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )" clearable />
						</el-form-item>
						<el-form-item label="收入一级项目:" prop="type1name">
							<el-input v-model.trim="selectSearchForm.type1name" @keyup.enter.native="onSelectSearchClick(
								   selectTableUrl,
								   null,
								   Object.assign({}, selectSearchForm)
								 )" clearable />
						</el-form-item>
						<el-form-item label="收入类别:" prop="typename">
							<el-input v-model.trim="selectSearchForm.typename" @keyup.enter.native="onSelectSearchClick(
								  selectTableUrl,
								  null,
								  Object.assign({}, selectSearchForm)
								)" clearable />
						</el-form-item>
					</el-form>
				</div>
				<div class="r-w">
					<el-button type="primary" icon="el-icon-search" :loading="!$store.state.global.isEndRequest"
						class="btn-2b5a95" @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )
            ">搜索</el-button>
					<el-button type="primary" @click="selectHanle">重置</el-button>
					<el-button type="primary" :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            " v-if="$store.state.global.searchExtend_2" class="btn-2b5a95" @click="onExtendClick('isSearchExtend_2')">
						{{ $store.state.global.searchExtendText_2 }}</el-button>
				</div>
			</div>

			<el-table
				:data="selectTable.list"
				style="width: 100%"
				border
				stripe
				:key="key"
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
				@selection-change="handleSelectionChange"
				ref="moneyTable">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column label="流水号" prop="id" align="center" width="100"></el-table-column>
				<el-table-column label="收入类别名称" prop="typename" align="center" width="150"></el-table-column>
				<el-table-column label="收入一级项目名称" prop="type1name" align="center" width="150"></el-table-column>
				<el-table-column label="收入二级项目名称" prop="name" align="center" width="150"></el-table-column>
				<el-table-column label="关联院校名称" prop="collegename" align="center" width="150"></el-table-column>
			</el-table>
			<el-pagination background @current-change="
          (val, data) =>
            handleSelectChange(val, Object.assign({}, selectSearchForm))
        " :current-page="selectPageInfo.pageindex" :page-size="selectPageInfo.pagesize"
				:page-sizes="[10, 20, 30, 40, 50]" layout="total, prev, pager, next, jumper"
				:total="selectTable.totalcount"></el-pagination>
		</el-dialog>
		<!-- 选择收入明细 -->
		<el-dialog class="btn-2b5a95" width="50%" v-dialogDrag :close-on-click-modal='false' @close="onCloseSelect"
			title="选择收入明细：" :visible.sync="showSelectItemModal" v-if="showSelectItemModal" append-to-body>
			<el-button type="primary" @click="onConfirmMoneyinitem">确定</el-button>

			<div class="index_2 btn-2b5a95">
				<div class="l-w" :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }">
					<el-form label-position="right" :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            " :model="selectSearchForm" @submit.native.prevent>
						<el-form-item label="名称:" prop="name">
							<el-input v-model.trim="selectSearchForm.name" @keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )" clearable />
						</el-form-item>
					</el-form>
				</div>
				<div class="r-w">
					<el-button type="primary" icon="el-icon-search" :loading="!$store.state.global.isEndRequest"
						class="btn-2b5a95" @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )
            ">搜索</el-button>
					<el-button type="primary" @click="selectHanle">重置</el-button>
					<el-button type="primary" :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            " v-if="$store.state.global.searchExtend_2" class="btn-2b5a95" @click="onExtendClick('isSearchExtend_2')">
						{{ $store.state.global.searchExtendText_2 }}</el-button>
				</div>
			</div>

			<el-table
				:data="selectTable.list"
				style="width: 100%"
				border
				stripe
				:key="key"
				:header-cell-style="{background: '#004d8c', color:'#FFFFFF'}"
				@selection-change="handleSelectionChange">
				<el-table-column type="selection" width="55"></el-table-column>
				<el-table-column label="流水号" prop="id" align="center" width="100"></el-table-column>
				<el-table-column label="名称" prop="name" align="center" width="100"></el-table-column>
			</el-table>
			<el-pagination background @current-change="
          (val, data) =>
            handleSelectChange(val, Object.assign({}, selectSearchForm))
        " :current-page="selectPageInfo.pageindex" :page-size="selectPageInfo.pagesize"
				:page-sizes="[10, 20, 30, 40, 50]" layout="total, prev, pager, next, jumper"
				:total="selectTable.totalcount"></el-pagination>
		</el-dialog>
		<!-- 打印收据 -->
		<el-dialog class="btn-2b5a95" width="1000px" v-dialogDrag @close="onCloseSelect" title="打印收据:"
			:close-on-click-modal='false' :visible.sync="shoujuPaper" v-if="shoujuPaper" append-to-body>
			<object class="aaa" id="ReportViewer" name="ReportViewer" type="application/x-grplugin-printviewer"
				width="900px" height="500px" style="margin: auto;">
				<param name="ReportURL" :value="this.$url.upload + '/report/shouju.grf'">
				<param name="DataURL" value="">
				<param name="AutoRun" value=true>
				<param name="SerialNo"
					value="4DFB949E066NYS7W11L8KAT53SA177391Q9LZQ094WUT9C9J3813SX8PTQC4ALPB9UAQN6TMA55Q3BN8E5726Z5A839QAD9P6E76TKNK5">
				<param name="UserName" value="锐浪报表插件本机开发测试注册">
			</object>
		</el-dialog>
		<!-- 退款 -->
		<el-dialog class="btn-2b5a95" width="35%" v-dialogDrag title="申请退款：" :close-on-click-modal='false'
			:visible.sync="showRefundModal" v-if="showRefundModal" append-to-body>
			<el-form :model="dynamicValidateForm" ref="dynamicValidateForm" class="demo-dynamic form-item-w-100"
				status-icon :rules="rules">
				<el-form-item label="学生姓名:">
					<el-input v-model="dynamicValidateForm.qianzainame" disabled />
				</el-form-item>
				<el-form-item label="手机:">
					<el-input v-model="dynamicValidateForm.mobile" disabled />
				</el-form-item>
				<el-form-item prop="realrecvmoney" label="申请退费金额:" :rules="[{ required: true, validator: checkfield }]">
					<el-input v-model.trim="dynamicValidateForm.realrecvmoney" clearable />
				</el-form-item>
				<el-form-item prop="reason" label="申请退费理由:" :rules="[{ required: true, message: '必填字段' }]">
					<el-input v-model.trim="dynamicValidateForm.reason" clearable />
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="onSubmitRefundForm('dynamicValidateForm')">提交
				</el-button>
			</span>
		</el-dialog>
		<!-- 增加 -->
		<el-dialog class="btn-2b5a95" width="50%" v-dialogDrag :close-on-click-modal='false' title="申请优惠"
			:visible.sync="showDiscountModal" v-if="showDiscountModal" append-to-body>
			<el-form :model="dynamicValidateForm" ref="dynamicValidateForm" label-width="170px"
				class="demo-dynamic form-item-w-50" status-icon :rules="rules">
				<el-form-item prop="companyorgname" label="所属分公司:">
					<el-select v-model="dynamicValidateForm.companyorgname" placeholder="请选择"
						:disabled="!$store.state.login.isMaxAuth"
						@change="onChangeCompany($event, 'dynamicValidateForm')" disabled>
						<el-option v-for="item in $store.state.selectData.companyList" :key="item.key" :label="item.val"
							:value="item.key" disabled></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="schoolorgname" label="所属教学点:">
					<el-select v-model="dynamicValidateForm.schoolorgname" placeholder="请选择"
						@change="onChangeSchool($event, 'dynamicValidateForm')" disabled>
						<el-option v-for="item in $store.state.selectData.schoolList" :key="item.key" :label="item.val"
							:value="item.key" disabled></el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="qianzainame" label="学生:">
					<el-input v-model="dynamicValidateForm.qianzainame" disabled />
				</el-form-item>
				<el-form-item prop="grade" label="年级:">
					<el-input v-model="dynamicValidateForm.grade" disabled />
				</el-form-item>
				<el-form-item prop="discountamount" label="优惠金额:">
					<el-input v-model.trim="dynamicValidateForm.discountamount" clearable />
				</el-form-item>
				<el-form-item prop="discountType" label="优惠类型:">
					<el-select v-model="dynamicValidateForm.discountType" placeholder="请选择" clearable>
						<el-option
							v-for="item in discountTypeList"
							:key="item.key"
							:value="item.key"
							:label="item.val"
							clearable>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="iyear" label="优惠学年:">
					<el-select v-model="dynamicValidateForm.iyear" placeholder="请选择" clearable>
						<el-option v-for="item in discountYear" :key="item.val" :value="item.key" :label="item.val" clearable>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="remarks" label="备注:">
					<el-input v-model.trim="dynamicValidateForm.remarks" type="textarea" :rows="6" />
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitAmountForm('dynamicValidateForm')"
					:loading="!$store.state.global.isEndRequest">提交</el-button>
			</div>
		</el-dialog>

		<el-dialog
			class="btn-2b5a95"
			width="70%"
			v-dialogDrag
			:close-on-click-modal='false'
			title="确认与开课"
			:visible.sync="classesModal"
			v-if="classesModal"
			append-to-body>
			<el-form :model="rulesValidateForm" ref="rulesValidateForm" label-width="170px"
				class="demo-dynamic form-item-w-50" status-icon :rules="rules">
				<el-form-item label="题库">
					<template>
						<el-checkbox-group
							v-model="rulesValidateForm.question"
						   >
						   <div v-for="(date, adIndex) in questionList" :key="adIndex">
						     <el-checkbox
								:label="date.id"
						       >
							   {{date.questionname}}
							</el-checkbox>
								<el-form-item style="margin-left: 20px; margin-bottom: 32px;">
									<el-date-picker
											v-model="questionList[adIndex].questionvalidity"
											type="date"
											@change="startTime($event, adIndex)"
											value-format="yyyy-MM-dd"
											placeholder="请设置课程有效期">
									</el-date-picker>
								</el-form-item>
							</div>
						   </el-checkbox-group>
					</template>
				</el-form-item>
				<el-form-item label="网课">
					<template>
						<el-checkbox-group
							v-model="rulesValidateForm.course"
						   >
						   <div v-for="(lay, adIndex) in videoList" :key="adIndex">
						     <el-checkbox
								:label="lay.id"
						       >
							   {{lay.coursename}}
							</el-checkbox>
								<el-form-item style="margin-left: 20px; margin-bottom: 32px;">

									<el-date-picker
											v-model="videoList[adIndex].coursevalidity"
											type="date"
											@change="onChangeQuestion($event, adIndex)"
											value-format="yyyy-MM-dd"
											placeholder="请设置网课有效期">
									</el-date-picker>
								</el-form-item>
							</div>
						   </el-checkbox-group>
					</template>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary"
					@click ="submitclassesForm('rulesValidateForm')"
					:loading="!$store.state.global.isEndRequest"
					>提交</el-button>

			</div>
		</el-dialog>
		<Export :exportTitle="'学历继续教育'" :transferDialog="transferDialog" :exportUrl="exportUrl"
			:exportexcelUrl="exportexcelUrl" :exportsaveUrl="exportsaveUrl" :searchForm="searchForm"
			@onCloseExport="onCloseExport"></Export>
		<Import :importDialog="importDialog" :importSaveUrl="importSaveUrl" @onCloseImport="onCloseImport"
			@getDataList="getDataList" :downloadUrl="downloadUrl"></Import>
	</div>
</template>
<script src="../../../utils/createControl.js?r=${r}"></script>
<script>
	import {
		CreateReport,
		CreatePrintViewerEx2,
		CreateDisplayViewerEx2,
		CreateDesignerEx,
		CreatePrintViewerEx,
		CreateDisplayViewerEx,
		CreateDisplayViewer,
		CreatePrintViewer,
		CreateDesigner,
	} from '@/utils/createControl';
	import {
		part
	} from "@/utils/mixins";
	import {
		mapActions
	} from "vuex";
	import {
		student_importsave,
		student_list,
		student_input,
		student_input3,
		videocourse_dialogresult,
		question_dialogresult,
		student_save,
		student_delete,
		student_checkincollect,
		student_incollect,
		student_savecollect,
		student_checkapplyrefund,
		student_applyrefund,
		studentfeeback_savestudent,
		student_changeflag,
		studentpotential_show,
		student_checkprintshouju,
		student_printshouju,
		student_printshoujuPaper,
		discount_save,
		collegesrceivable_checkprintshouju,
		student_export,
		student_exportexcel,
		student_exportsave,
		student_exportstudent,
		studentuser_savestudent
	} from "@/request/api/allChildrenProject";
	import {
		dic_combox,
		_allCompany,
		_allSchool,
		_moneyinitem_dialogresult,
		_moneyintype2_dialogresult,
		_account_dialogresult,
		_shouju_getshoujuinfo,
		_enrollplandetail_dialogresult,
		_collegefeestandard_dialogresult,
		_collegecoporg_dialogresult,
		_fileupload_save,
	} from "@/request/api/public";
	import {
		myRequest
	} from "@/request/index";
	import {
		selectCheck,
		confirmCallBack,
		messageTip,
		createObjParams,
		formatDare,
	} from "@/utils/util";
	import {
		_formalStudent,
		_formalStudents,
		_bankorcash,
		_chongzhanflag,
		_available,
		_paidTypes,
		_projectTypes,
		_discount,
		_approveflags,
		_passflags,
		_discountYear,
		areaflag,
		_isAppselect,
	} from "@/assets/js/filedValueFlag";
	import {
		_refundForm
	} from "@/assets/js/initTableData";
	import Export from '@/components/Export/index.vue';
	import Import from "@/components/Import/index.vue";
	export default {
		mixins: [part],
		name: "student",
		components: {
			Export,
			Import
		},
		props: {
			moneyinitem_dialogresult: {
				default: () => _moneyinitem_dialogresult,
			},
			moneyintype2_dialogresult: {
				default: () => _moneyintype2_dialogresult,
			},
			account_dialogresult: {
				default: () => _account_dialogresult,
			},
			enrollplandetail_dialogresult: {
				default: () => _enrollplandetail_dialogresult,
			},
			paidTypes: {
				default: () => _paidTypes,
			},
			collegefeestandard_dialogresult: {
				default: () => _collegefeestandard_dialogresult,
			},
			collegecoporg_dialogresult: {
				default: () => _collegecoporg_dialogresult,
			},
			exportUrl: {
				default: () => student_export,
			},
			exportexcelUrl: {
				default: () => student_exportexcel,
			},
			exportsaveUrl: {
				default: () => student_exportsave,
			},
		},
		data() {
			return {
				valTowValue: (rule, value, callback) => {
					if(value == "" || value === null) {
						callback(new Error('请选择日期'))
					} else if(this.dynamicValidateForm.question === "" || this.dynamicValidateForm.question === null) {
						callback(new Error())
					} else {
						callback()
					}
				},
				checkfield: (rule, value, callback) => {
					if (!(value + "")) {
						return callback(new Error("必填字段"));
					} else {
						if (isNaN(Number(value))) {
							callback(new Error("请输入数字值"));
						} else {
							callback();
						}
					}
				},

				followuserid: null,
				srcList: [],
				questionvalidity: [],
				importSaveUrl: "",
				downloadUrl: "",
				importDialog: false, //导入框
				checked: false,
				educationList: [],
				questionAll: [],
				courseAll: [],
				discountYear: _discountYear,
				discountTypeList:[],
				showDiscountModal: false, //增加
				currentTab: 0,
				currentClass: 0,
				transferDialog: false, //导出框
				classesModal: false,
				typeOptions: [],
				areaflags: areaflag,
				languageTypeList: [], //语言种类
				arr_province: ['中专', '高升专', '高升本', '专升本', '研究生'],
				brandObj: _isAppselect,
				tabList: ['档案', '学历报名', '收费', '培训', '学籍 ', '跟进', '成考辅导班', '代预报名', '证件信息'],
				bankorcash: _bankorcash, // 银行现金
				itemId: null, //首表格项id
				rowItem: null, //表格项
				searchForm: {},
				tableRes: {}, //列表接口
				rulesValidateForm: {}, //开课与确认
				questionList: [],
				videoList: [],
				pageinfo: {
					pageindex: 1,
					pagesize: 10,
				}, //页数查询
				available: _available,
				chongzhanflag: _chongzhanflag,
				showAddModal: false, //显示表单框
				showSchoolTable: false, //显示学院表格
				multipleSelection: [], //选中的表格项
				dynamicValidateForm: {}, //表单
				showSelectCollegeModal: false, // 院校专业弹框
				shoujuPaper: false, //打印收据
				rules: {
					studytypeval: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}],
					discountType: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}, ],
					iyear: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}, ],
					guakaoschoolorgname: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}],
					course: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}],
					graduatetime: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}],
					zhuankeno: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}],
					zhuankemajor: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}],
					graduateschool: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}],
					eduval: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}],
					hukouaddr: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}],
					birthday: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}],
					name: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}],
					sexkey: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}],
					nationkey: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}],
					polikey: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}],
					homeaddr: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}],
					homezipcode: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}],
					job: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}],
					idcard: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}, ],
					year: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}],
					grade: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}],
					collegename1: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}, ],
					majorname1: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}],
					levelval: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}],
					companyorgname: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}, ],
					schoolorgname: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}, ],
					qianzainame: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}],
					shoujucode: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}],
					bankorcash: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}],
					shouldrecvmoney: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}, ],
					realrecvmoney: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}, ],
					typem2oneyintypename: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}, ],
					itemmoneyinitemname: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}, ],
					discountamount: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}, ],
					isbookfee: [{
						trigger: "blur",
						message: "必填字段",
						required: true
					}, ],
				}, //表单规则
				projecttypeList: _projectTypes, // 项目类型数组
				studyTypeList: [], // 学习形式
				nationList: [], // 民族
				policyList: [], //政治面貌
				enrollyearList: [], //招生年份
				leveltypeList: [], //层次类型
				companyList: [], //所有分公司
				schoolList: [], //所有教学点
				policyList: [],
				studentfromtypeList: [],
				intentionList: [],
				enrollyearList: [],
				gradeList: [],
				vstudent: {},
				vmoneyinlist: [],
				showRefundModal: false, //退费框
				key: '',
				//收费表单
				showEnrolModal: false, //收费框
				selectType: null,
				selectTableUrl: null,
				selectTableType: null,
				selectTable: {},
				showSelectItemModal: false, // 明细弹框
				showSelectTypeModal: false, // 二级弹框
				showSelectAccountModal: false, // 银行弹框
				showCollegemajorModal: false, // 选择院校专业
				showCollegecoporg: false, //校外教学点
				selectSearchForm: {}, // 选择搜索
				selectPageInfo: {
					pageindex: 1,
					pagesize: 10,
				}, // 选择框分页

				selectSchoolorgid: null,

				formalStudent: _formalStudent,
				formalStudents: _formalStudents,

				studyTypes: [], //学习形式

				formalStudent: _formalStudent,
				formalStudents: _formalStudents,

				tabIData: null, //不同tab栏数据类型可能不同
				detailData: {},
				baokaokeleiList: [],
				mapbean: {}, // 存储搜索值

				showPrintReceipt: false, //打印收据
				// 查看详情
				showDetailModal: false,
				vstudentpotential: {},
				showDetailList: {},
				activeName: "vstudentpotential",
				tabId: null,
				showDeliModal: false,
				fds: new FormData(),
				fromtypeList: [],
				studentfromtypeList: [],
				registrationPointList: [],
				idphoto: "",
				yearList: [{
						value: '0',
						label: '2019'
					},
					{
						value: '1',
						label: '2020'
					},
					{
						value: '2',
						label: '2021'
					},
					{
						value: '3',
						label: '2022'
					},
					{
						value: '4',
						label: '2023'
					},
					{
						value: '5',
						label: '2024'
					},
					{
						value: '6',
						label: '2025'
					}
				],
			};
		},
		watch: {
			'$route': "getDataList"
		},
		created() {
			this.getDataList();
			this.nation();
			this.leveltype();
			this.studyType();
			this.org_combox();
			this.org_comboxchild(this.dynamicValidateForm.companyorgid);
			this.dic_combox({
				that: this,
				typecode: "registrationPoint",
				list: "registrationPointList",
			});
			this.dic_combox({
				that: this,
				typecode: "language",
				list: "languageTypeList",
			});
			this.dic_combox({
				that: this,
				typecode: "nation",
				list: "nationList",
			});
			this.dic_combox({
				that: this,
				typecode: "occupation",
				list: "occupationList",
			});
			this.dic_combox({
				that: this,
				typecode: "baokaokelei",
				list: "baokaokeleiList",
			});
			this.dic_combox({
				that: this,
				typecode: "studytypeval",
				list: "studytypevalList",
			});
			this.dic_combox({
				that: this,
				typecode: "policy",
				list: "policyList",
			});
			this.dic_combox({
				that: this,
				typecode: "studentfromtype",
				list: "studentfromtypeList",
			});

			this.dic_combox({
				that: this,
				typecode: "intention",
				list: "intentionList",
			});
			this.dic_combox({
				that: this,
				typecode: "enrollyear",
				list: "enrollyearList",
			});
			this.dic_combox({
				that: this,
				typecode: "grade",
				list: "gradeList",
			});
			this.dic_combox({
				that: this,
				typecode: "projecttype",
				list: "projecttypeList",
			});
			this.dic_combox({
				that: this,
				typecode: "leveltype",
				list: "leveltypeList",
			});
			this.dic_combox({
				that: this,
				typecode: "preExamination",
				list: "preExaminationList",
			});
			this.dic_combox({
				that: this,
				typecode: "registeredResidence",
				list: "registeredResidenceList",
			});
			this.dic_combox({
				that: this,
				typecode: "fromtype",
				list: "fromtypeList",
			});
			this.dic_combox({
				that: this,
				typecode: "education",
				list: "educationList",
			});
			this.dic_combox({
				that: this,
				typecode: "discountType",
				list: "discountTypeList",
			});
			window.addEventListener("keyup", this.enterSelect);
		},
		methods: {
			...mapActions("global", ["onExtendClick", "isExpand"]),
			...mapActions("selectData", ["org_combox", "org_comboxchild", 'dic_combox']),
			//上传文件前 before
			beforeUpload(file) {
				this.fds.append("upfile", file); // 传文件
			},
			//上传文件 upload
			uploadFile(prop) {
				myRequest({
					method: "post",
					url: _fileupload_save,
					data: this.fds,
				}).then((res) => {
					if (res.data.code === "200") {
						this.fds = new FormData();
						this.dynamicValidateForm[prop] = res.data.url;
					}
				});
			},
			reset() {
				this.searchForm = {
					qianzainame: null,
					grade: null,
					isokflag: null,
					levelval: null,
					projecttype: null,
					mobile: null,
					followusername: null,
					collegename1: null,
					majorname1: null,
					shoujucode: null,
					schoolorgname: null,
					t1: null,
					t2: null,
					t3: null,
					t4: null,
					fromtypeval: null,
					studentfromtypeval: null,
					year: null,
					companyorgname: null,
					studentidcard: null,
					iscooperation: null,
					isdiscountfee: null,
					isarrears: null
				}
			},
			//重置
			resetQuery() {
				this.reset();
				this.getDataList();
			},
			vbs(val) {
				this.srcList = []
				this.srcList.push(val)
			},
			enterSelect(e) {
				if (e.keyCode == 13) {
					this.getDataList();
				}
			},
			isarrearsVisible(e) {
				if (!e) {
					this.$refs.isarrearsRef.blur();
				}
			},
			discountfeeVisible(e) {
				if (!e) {
					this.$refs.isdiscountfeeRef.blur();
				}
			},
			visibleisok(e) {
				if (!e) {
					this.$refs.isok.blur();
				}
			},
			visibleLevelval(e) {
				if (!e) {
					this.$refs.levelref.blur();
				}
			},
			projectVisible(e) {
				if (!e) {
					this.$refs.projectref.blur();
				}
			},
			fromtyVisible(e) {
				if (!e) {
					this.$refs.fromtyref.blur();
				}
			},
			yearVisible(e) {
				if (!e) {
					this.$refs.yearRef.blur();
				}
			},
			selectQuery() {
				this.selectSearchForm = {
					accountname: null,
					bankname: null
				}
			},
			iscooperationVisible(e) {
				if (!e) {
					this.$refs.iscooperationRef.blur();
				}
			},
			startTime(val, index) {
				this.questionAll[index] = formatDare(this.questionList[index].questionvalidity,"YYYY-MM-DD HH:mm:ss");
				console.log(this.questionAll, '---')
			},
			onChangeQuestion(val, index) {
				this.courseAll[index] =	 formatDare(this.videoList[index].coursevalidity ,"YYYY-MM-DD HH:mm:ss");
				console.log(this.courseAll, 'videoList')
			},
			handleQuery() {
				this.selectQuery();
				this.onSelectData(this.selectTableUrl, null, {
					schoolorgid: this.dynamicValidateForm.schoolorgid
				});
			},
			handleselect() {
				this.selectSearchForm = {
					name: null,
					type1name: null,
					typename: null
				}
			},
			selectHanle() {
				this.handleselect();
				this.onSelectData(this.selectTableUrl, null, null);
			},
			handleForm() {
				this.selectSearchForm = {
					collegename: null,
					collegemajorname: null,
					majorlevelval: null,
					schoolorgname: null,
					classname: null
				}
			},
			hanleSearchForm() {
				this.handleForm();
				this.onSelectData(this.selectTableUrl, null, Object.assign({
					schoolorgid: this.dynamicValidateForm.schoolorgid,
					enrollyearval: this.dynamicValidateForm.year,
					majorlevelval: this.dynamicValidateForm.levelval,
					projectname: this.dynamicValidateForm.projecttype,
				}));
			},
			//隐藏和显示
			handleShow() {
				this.showDetailModal = false;
			},
			//关闭导出框
			onCloseExport(bool) {
				this.transferDialog = false;
			},
			//项目类型
			onChangeprojecttype(val, form) {
				this[form].projecttype = val;
				this[form].year = "";
				this[form].grade = "";
				this[form].collegename1 = "";
				this[form].majorname1 = "";
				this[form].guakaoschoolorgname = "";
				this[form].guakaocompanyorgname = "";
				this[form].classaddress = "";
			},
			onChangelevelval(val, form) {
				this[form].levelval = val;
				this[form].year = "";
				this[form].grade = "";
				this[form].collegename1 = "";
				this[form].majorname1 = "";
			},
			onChangestudytypeval(e) {
				this.dynamicValidateForm.studytypekey = e.key;
				this.dynamicValidateForm.studytypeval = e.val;
				this.dynamicValidateForm.year = "";
				this.dynamicValidateForm.grade = "";
				this.dynamicValidateForm.collegename1 = "";
				this.dynamicValidateForm.majorname1 = "";
				this.dynamicValidateForm.guakaoschoolorgname = "";
				this.dynamicValidateForm.guakaocompanyorgname = "";
				this.dynamicValidateForm.classaddress = "";
			},
			//导出
			btnInduce() {
				this.transferDialog = true;
			},
			toggleTab(current) {
				this.currentTab = current;
				this.currentClass = current;
			},
			// 招生年份 change
			ChangeYear() {
				this.dynamicValidateForm.grade = "";
				this.dynamicValidateForm.collegename1 = "";
				this.dynamicValidateForm.majorname1 = "";
			},

			//报考科类
			registereChange(obj) {
				this.dynamicValidateForm.registeredSubjects = obj.val;
			},
			changeSelect() {
				for (const k in this.arr_province) {
					if (this.dynamicValidateForm.levelval === this.arr_province[k]) {
						this.typeOptions = this.brandObj[this.dynamicValidateForm.levelval]
					} else {
						this.dynamicValidateForm.course = "";
					}
				}
				this.dynamicValidateForm.year = "";
				this.dynamicValidateForm.grade = "";
				this.dynamicValidateForm.collegename1 = "";
				this.dynamicValidateForm.majorname1 = "";
				this.dynamicValidateForm.guakaoschoolorgname = "";
				this.dynamicValidateForm.guakaocompanyorgname = "";
				this.dynamicValidateForm.classaddress = "";
			},
			changeCourse() {
				this.typeOptions = this.brandObj[this.dynamicValidateForm.levelval]
			},
			//改变选择框 change
			onChangeSelect(val, prop) {
				this.dynamicValidateForm[prop] = val;
			},
			// 非正式学生 click
			btnstudent() {
				selectCheck(this.multipleSelection, "入学注册", false, () => {
					confirmCallBack({
						title: "提示",
						content: "是否获取入学注册应收",
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						success: () => {
							this.handleState(this.multipleSelection[0].id, 1);
						},
						fail: () => {
							this.$message({
								type: 'info',
								message: '已取消操作'
							})
						},
					});
				});
			},
			// 非正式学生 api
			handleState(id, flag) {
				myRequest({
					url: student_changeflag,
					data: {
						id: id,
						flag: 1, //0非正式
					},
				}, {
					isRefTableList: true,
					that: this,
				});
			},
			//改变分公司 change
			onChangeCompany(val, form) {
				this[form].schoolorgname = "";
				this[form].schoolorgid = "";
				this[form].companyorgid = val;
				this[form].shoujucode = "";
				this.org_comboxchild(val);
			},
			onChangeSchool(val, form) {
				this[form].schoolorgid = val;
			},
			//搜索 click
			seach() {
				this.pageinfo.pageindex = 1;
				this.getDataList();
			},
			//列表查询 api
			getDataList() {
				myRequest({
					url: student_list,
					data: Object.assign(this.searchForm, this.pageinfo),
				}).then((res) => {
					if (res.data.code === "200") {
						this.tableRes = res.data.data;
					}
				});
			},
			//学生核准
			btnImport() {
				this.importSaveUrl = student_importsave;
				this.downloadUrl = "/download/业务管理/学生核准模板.xls";
				this.importDialog = true;
			},
			//关闭导入框
			onCloseImport(bool) {
				this.importDialog = bool;
			},
			//打印收据纸质
			btnPrintReceipt2() {
				selectCheck(this.multipleSelection, "操作", false, () => {
					this.selectShouju(this.multipleSelection[0].id)
				})
			},
			//检查可否打印收据 api 纸质版本
			selectShouju() {
				myRequest({
					url: student_checkprintshouju,
					data: {
						id: this.multipleSelection[0].id,
					}
				}).then((res) => {
					if (res.data.code === "200") {
						this.shoujuPaper = true;
						this.printshoujuPaper();
					}
				})
			},
			printshoujuPaper() {
				myRequest({
					url: student_printshoujuPaper,
					data: {
						id: this.multipleSelection[0].id
					}
				}).then((res) => {
					if (res.data.code === "200") {
						this.tshouju = res.data.data.tshouju;
						this.vstudent = res.data.data.vstudent;
						this.vmoneyinlist = res.data.data.vmoneyinlist;
						var ReportViewer = document.getElementById("ReportViewer");
						ReportViewer.Stop();
						var Report = ReportViewer.Report;
						var Recordset = Report.DetailGrid.Recordset;
						//获得参数
						var ppzh = ReportViewer.Report.ParameterByName("pzh");
						var pname = ReportViewer.Report.ParameterByName("name");
						var psfzhm = ReportViewer.Report.ParameterByName("sfzhm");
						var pinserttime = ReportViewer.Report.ParameterByName("inserttime");
						var pgrade = ReportViewer.Report.ParameterByName("grade");
						var plevel = ReportViewer.Report.ParameterByName("level");
						var pmajor = ReportViewer.Report.ParameterByName("major");

						var premark = ReportViewer.Report.ParameterByName("remark");
						var pshoukuairen = ReportViewer.Report.ParameterByName("shoukuairen");
						var pkaipiaoren = ReportViewer.Report.ParameterByName("kaipiaoren");

						ppzh.AsString = this.tshouju.code;
						pname.AsString = this.vstudent.qianzainame;
						psfzhm.AsString = this.vstudent.studentidcard === null ? '' : this.vstudent.studentidcard;
						pinserttime.AsString = formatDare(
							this.vstudent.shoukuantime,
							"YYYY-MM-DD HH:mm:ss"
						);
						pgrade.AsString = this.vstudent.grade;
						plevel.AsString = this.vstudent.levelval;
						pmajor.AsString = this.vstudent.majorname1;

						premark.AsString = this.vstudent.shoukuanrenark === null ? '' +
							'* 德立教育集团投诉与建议: 020-89226488' : this.vstudent.shoukuanrenark +
							'* 德立教育集团投诉与建议: 020-89226488';
						pshoukuairen.AsString = this.vmoneyinlist[0].inusername;
						pkaipiaoren.AsString = this.vmoneyinlist[0].inusername;

						// 	//获得字段
						var fcollege = ReportViewer.Report.FieldByName("college");
						var fitem0 = ReportViewer.Report.FieldByName("item0");
						var fprice0 = ReportViewer.Report.FieldByName("price0");
						var yuan0 = ReportViewer.Report.FieldByName("yuan0");
						var fitem = ReportViewer.Report.FieldByName("item");
						var fprice = ReportViewer.Report.FieldByName("price");
						var pmaohao = ReportViewer.Report.FieldByName("maohao");
						var maohao0 = ReportViewer.Report.FieldByName("maohao0");
						var yuan = ReportViewer.Report.FieldByName("yuan");

						//填充字段
						Report.PrepareRecordset();
						for (var index = 0; index < this.vmoneyinlist.length; index++) {
							Recordset.Append();
							fcollege.AsString = this.vstudent.collegename1;
							maohao0.AsString = "：";
							yuan0.AsString = "元";
							fprice.AsFloat = this.vmoneyinlist[index].amount;
							fitem0.AsString = this.vmoneyinlist[index].itemmoneyinitemname;
							fitem.AsString = this.vmoneyinlist[index].itemmoneyinitemname;
							pmaohao.AsString = "：";
							fprice0.AsFloat = this.vstudent.shouldrecvmoney;
							yuan.AsString = "元";
							Recordset.Post();
						}

						if(this.vstudent.discountfee !== 0){
								Recordset.Append();
								fitem0.AsString ="优惠" ;
								maohao0.AsString ="："
								fprice0.AsFloat = 0 - this.vstudent.discountfee;
								yuan0.AsString ="元" ;
								Recordset.Post();
						   }
						ReportViewer.Start();
					}
				});
			},
			//切换当前页
			handleCurrentChange(val) {
				this.pageinfo.pageindex = val;
				this.getDataList();
			},
			//触发所有btn方法
			onClickBtn(methodsName) {
				this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
			},
			//增加
			btnDiscount() {
				selectCheck(this.multipleSelection, "申请优惠", false, () => {
					this.btnDiscountPage(
						this.multipleSelection[0].id,
						this.multipleSelection[0].qianzaiid
					);
					this.showDiscountModal = true;
				})
			},
			//增加 api
			btnDiscountPage(id, qianzaiid) {
				myRequest({
					url: student_input,
					data: {
						id: id,
					},
				}).then((res) => {
					if (res.data.code === "200") {
						this.dynamicValidateForm = Object.assign(
							res.data.data.vstudentpotential || {},
							res.data.data.vstudent
						);
						this.org_comboxchild(this.dynamicValidateForm.companyorgid);
						this.dynamicValidateForm.qianzaiid = qianzaiid;
						this.itemId = id;
					}
				});
			},
			//提交-（添加，修改） api
			submitAmountForm(dynamicValidateForm) {
				this.$refs[dynamicValidateForm].validate((valid) => {
					if (valid) {
						myRequest({
							url: discount_save,
							data: {
								companyorgid: this.dynamicValidateForm.companyorgid,
								schoolorgid: this.dynamicValidateForm.schoolorgid,
								studentid: this.dynamicValidateForm.id,
								grade: this.dynamicValidateForm.grade,
								companyorgid: this.dynamicValidateForm.companyorgid,
								iyear: this.dynamicValidateForm.iyear,
								discountamount: this.dynamicValidateForm.discountamount,
								remarks: this.dynamicValidateForm.remarks,
								qianzaiid: this.dynamicValidateForm.qianzaiid,
								discounttype: this.dynamicValidateForm.discountType
							},
						}, {
							that: this,
							isRefTableList: true,
							modal: "showDiscountModal",
						});
					}
				});
			},
			//申请退费click
			btnApplyRefund() {
				selectCheck(this.multipleSelection, "申请退费", false, () => {
					this.checkapplyrefund(this.multipleSelection[0].id);
				});
			},

			//检查是否可以申请退费 api
			checkapplyrefund(id) {
				myRequest({
					url: student_checkapplyrefund,
					data: {
						id: id,
					},
				}, {
					that: this,
					methodName: "applyRefund",
				});
			},
			//申请退费 api
			applyRefund() {
				myRequest({
					url: student_applyrefund,
					data: {
						id: this.multipleSelection[0].id,
					},
				}).then((res) => {
					if (res.data.code === "200") {
						this.dynamicValidateForm = res.data.data.tstudent;
						this.showRefundModal = true;
					}
				});
			},
			//保存退费 api
			onSubmitRefundForm(dynamicValidateForm) {
				this.$refs[dynamicValidateForm].validate((valid) => {
					if (valid) {
						myRequest({
							url: studentfeeback_savestudent,
							data: {
								id: this.dynamicValidateForm.id,
								qianzaiid: this.dynamicValidateForm.qianzaiid,
								shoujuid: this.dynamicValidateForm.shoujuid,
								companyorgid: this.dynamicValidateForm.companyorgid,
								schoolorgid: this.dynamicValidateForm.schoolorgid,
								feebackmoney: this.dynamicValidateForm.realrecvmoney,
								reasonsreason: this.dynamicValidateForm.reason,
							},
						}, {
							isRefTableList: true,
							that: this,
							modal: "showRefundModal",
						});
					}
				});
			},

			//删除click
			btnDel() {
				selectCheck(this.multipleSelection, "删除", true, () => {
					confirmCallBack({
						title: "提示",
						content: "此操作将永久删除选中的数据！",
						success: () => {
							this.delTableItem(this.multipleSelection.map((i) => i.id));
						},
						fail: () => {
							messageTip({
								message: "取消操作",
								type: "info",
							});
						},
					});
				});
			},
			//删除 api
			delTableItem(idArr) {
				myRequest({
					method: "post",
					url: student_delete,
					data: this.$qs.stringify({
						ids: idArr,
					}, {
						arrayFormat: "repeat",
					}),
				}, {
					isRefTableList: true,
					that: this,
				});
			},
			//变更状态 click
			btnChangeState() {
				selectCheck(this.multipleSelection, "变更状态", true, () => {
					confirmCallBack({
						title: "提示",
						content: "请选择要进行的变更操作",
						confirmButtonText: "确认",
						cancelButtonText: "非确认",
						distinguishCancelAndClose: true,
						success: () => {
							this.changeState(this.multipleSelection[0].id, 1);
						},
						fail: () => {
							this.changeState(this.multipleSelection[0].id, 0);
						},
					});
				});
			},
			//刷新 click
			btnReload() {
				this.getDataList();
			},
			btnExport() {
				confirmCallBack({
					title: "提示",
					content: "根据搜索条件进行导出。",
					success: () => {
						window.location.href =
							this.$url.Freemarker +
							student_exportstudent +
							`?${createObjParams(this.searchForm)}`;
					},
					fail: () => {
						messageTip({
							message: "取消操作",
							type: "info",
						});
					},
				});
			},
			//切换招生计划页时 change
			handleEnrollplanChange(val) {
				this.pageinfo.pageindex = val;
				this.enrollplan();
			},

			//  查看详情 tab切换 change
			handleClick() {
				this.btnShowDetail(this.tabId);
			},

			// 查看详情 clik
			btnShowDetail(qianzaiid) {
				this.tabId = qianzaiid;
				myRequest({
					url: studentpotential_show,
					data: {
						id: qianzaiid,
					},
				}).then((res) => {
					if (res.data.code === "200") {
						this.showDetailList = res.data.data;
						this.vstudentpotential = this.showDetailList.vstudentpotential;
						this.showDetailModal = true;
					}
				});
			},
			//打印收据 click
			btnPrintReceipt() {
				selectCheck(this.multipleSelection, "操作", false, () => {
					this.checkprintshouju(this.multipleSelection[0]);
				});
			},
			onfirmation() {
				selectCheck(this.multipleSelection, "修改", false, () => {
					this.classRoutePage(this.multipleSelection[0].id);
					this.rulesValidateForm = {
						course: [],
						question: [],
						studentid: this.multipleSelection[0].id,
					}
				});
			},
			classRoutePage(id) {
				myRequest({
					url: student_input3,
					data: {
						id: id,
					},
				}).then((res) => {
					if (res.data.code === "200") {
						this.classesModal = true;
						this.question();
						this.videocourse();
					}
				});

			},
			videocourse() {
				myRequest({
					url: videocourse_dialogresult,
					data: {},
				}).then((res) => {
					if (res.data.code === "200") {
						this.videoList = res.data.data.list
					}
				});

			},
			question() {
				myRequest({
					url: question_dialogresult,
					data: {},
				}).then((res) => {
					if (res.data.code === "200") {
						this.questionList = res.data.data.list;
					}
				});

			},
			submitclassesForm(rulesValidateForm) {
				this.rulesValidateForm.coursevalidity = this.courseAll
				console.log(this.rulesValidateForm.coursevalidity, 'coursevalidity')
				this.rulesValidateForm.questionvalidity = this.questionAll
				console.log(	this.rulesValidateForm.questionvalidity, 'this.questionvalidity')
					this.$refs[rulesValidateForm].validate((valid) => {
						if (valid) {
							myRequest({
								method: "post",
								url: studentuser_savestudent,
								data: this.$qs.stringify(this.rulesValidateForm, {
									arrayFormat: 'repeat'
							}),
							},{
								that: this,
								isRefTableList: true,
								modal: "classesModal",
							})
						}
						console.log(this.rulesValidateForm, '这个是---')
					})
			},

			getIdcard(idCard) {
				// console.log(idCard,'这个是身份证号码---');
				var birthday = "";
				var idCard = this.dynamicValidateForm.idcard;
				if (idCard != null && idCard != "") {
					if (idCard.length == 15) {
						birthday = "19" + idCard.substr(6, 6);
						// console.log(birthday,'这个数局是几位啊---');
					} else if (idCard.length == 18) {
						birthday = idCard.substr(6, 8);
						// console.log(birthday,'18位--');
					}
					this.dynamicValidateForm.birthday = birthday.replace(/(.{4})(.{2})(.{2})/, "$1-$2-$3 04:00:00");
					// console.log(this.dynamicValidateForm.birthday,'这个是出生年月日----')
				}
				return birthday;
			},
			//修改 click
			btnEdit() {
				selectCheck(this.multipleSelection, "修改", false, () => {
					this.editRoutePage(
						this.multipleSelection[0].id,
						this.multipleSelection[0].qianzaiid
					);
				});
			},
			//收费 click
			btnCharge() {
				selectCheck(this.multipleSelection, "收费", false, () => {
					this.checkincollect(this.multipleSelection[0].id);
				});
			},
			//修改路由页 api
			editRoutePage(id, qianzaiid) {
				myRequest({
					url: student_input,
					data: {
						id: id,
					},
				}).then((res) => {
					if (res.data.code === "200") {
						this.policy();
						this.enrollyear();
						this.dynamicValidateForm = Object.assign(
							res.data.data.vstudentpotential || {},
							res.data.data.vstudent
						);
						this.org_comboxchild(this.dynamicValidateForm.companyorgid);
						this.dynamicValidateForm.qianzaiid = qianzaiid;
						this.itemId = id;
						this.changeCourse();
						this.dynamicValidateForm.graduatetime = formatDare(
							this.dynamicValidateForm.graduatetime,
							"YYYY-MM-DD HH:mm:ss"
						);
						this.dynamicValidateForm.birthday = formatDare(
							this.dynamicValidateForm.birthday,
							"YYYY-MM-DD HH:mm:ss"
						);
						this.dynamicValidateForm.joinuptime = formatDare(
							this.dynamicValidateForm.joinuptime,
							"YYYY-MM-DD HH:mm:ss"
						);
						this.dynamicValidateForm.jobtime = formatDare(
							this.dynamicValidateForm.jobtime,
							"YYYY-MM-DD HH:mm:ss"
						);
						this.dynamicValidateForm.joinouttime = formatDare(
							this.dynamicValidateForm.joinouttime,
							"YYYY-MM-DD HH:mm:ss"
						);
						this.dynamicValidateForm.lastfollowtime = formatDare(
							this.dynamicValidateForm.lastfollowtime,
							"YYYY-MM-DD HH:mm:ss"
						);
						this.initEdit(res.data.data);

					}
				});
			},
			//初始化修改 event
			initEdit(resData) {
				this.$set(this.dynamicValidateForm, "moreItems1", []);
				// console.log(this.$set(this.dynamicValidateForm, "moreItems1", []), 'moreItems1这个的空的执行了哪里啊')
				this.$set(this.dynamicValidateForm, "moreItems2", []);
				// console.log(this.$set(this.dynamicValidateForm, "moreItems2", []), 'moreItems2这个的空的执行了哪里啊')
				for (let i in resData.vstudentresume) {
					if (resData.vstudentresume[i].resumetype === 0) {
						resData.vstudentresume[i].resumetype = 1
						// console.log(resData.vstudentresume[i].resumetype,'这个是数组里面的那些玩意儿')resumetype
						this.dynamicValidateForm.moreItems1.push(resData.vstudentresume[i])
						// console.log(this.dynamicValidateForm.moreItems1.push(resData.vstudentresume[i]),'这个是数组里面的那些数据啊啊')
					} else {
						resData.vstudentresume[i].membertype = 1
						// console.log(resData.vstudentresume[i].membertype, '这个是membertype')
						delete resData.vstudentresume[i].resumetype
						this.dynamicValidateForm.moreItems2.push(resData.vstudentresume[i])
						// console.log(this.dynamicValidateForm.moreItems2.push(resData.vstudentresume[i]), '哪里的啊-------')
					}
				}
				delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
				delete this.dynamicValidateForm.isoktime; //isoktime 该字段不用传
				delete this.dynamicValidateForm.lastinserttime; //lastinserttime 该字段不用传
				delete this.dynamicValidateForm.finishdate; //finishdate 该字段不用传
				delete this.dynamicValidateForm.shoukuantime; //shoukuantime 该字段不用传
				this.getIdcard();
				this.showAddModal = true;
			},
			//提交-（添加，修改）click
			onSubmitAddOrEditForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						const moreDataKey = ["moreItems1", "moreItems2"];
						const moreItemKey = [
							"duration",
							"appoint",
							"post",
							"xuezhival",
							"workingyears",
							"graduation",
							'resumetype',
							"resumename",
							"appellation",
							"age",
							"occupation",
							'membertype',
						];
						moreItemKey.map((i) => (this.dynamicValidateForm[i] = []));
						for (let moreKey in moreDataKey) {
							for (let j in moreItemKey) {
								for (let i in this.dynamicValidateForm[moreDataKey[moreKey]]) {
									for (let key in this.dynamicValidateForm[moreDataKey[moreKey]][i]) {
										if (this.dynamicValidateForm[moreDataKey[moreKey]][i][key] && key ===
											moreItemKey[j]) {
											//有值才push，否则是空字符串
											this.dynamicValidateForm[key].push(this.dynamicValidateForm[
												moreDataKey[moreKey]][i][key]);
										}
									}
								}
							}
						}

						this.submitAddOrEditForm();
					}
				});
			},
			//提交-（添加，修改） api
			submitAddOrEditForm() {
				this.dynamicValidateForm.id = this.itemId;
				this.dynamicValidateForm.graduatetime = formatDare(
					this.dynamicValidateForm.graduatetime,
					"YYYY-MM-DD HH:mm:ss"
				);
				this.dynamicValidateForm.birthday = formatDare(
					this.dynamicValidateForm.birthday,
					"YYYY-MM-DD HH:mm:ss"
				);
				this.dynamicValidateForm.joinuptime = formatDare(
					this.dynamicValidateForm.joinuptime,
					"YYYY-MM-DD HH:mm:ss"
				);
				this.dynamicValidateForm.joinouttime = formatDare(
					this.dynamicValidateForm.joinouttime,
					"YYYY-MM-DD HH:mm:ss"
				);
				delete this.dynamicValidateForm.feebacktime;
				delete this.dynamicValidateForm.edukey; //shoukuantime 该字段不用传
				myRequest({
					method: 'post',
					url: student_save,
					data: this.$qs.stringify(this.dynamicValidateForm, {
						arrayFormat: 'repeat'
					}),
				}, {
					isRefTableList: true,
					that: this,
					modal: "showAddModal",
				});
			},
			//添加一行 - （本人简历） click
			report_addFormItem1() {
				this.dynamicValidateForm.moreItems1.push({
					duration: "",
					appoint: "",
					post: "",
					xuezhival: "",
					workingyears: "",
					graduation: "",
					resumetype: 1,
				});
			},
			//添加一行 - （家庭成员） click
			report_addFormItem2() {
				this.dynamicValidateForm.moreItems2.push({
					resumename: "",
					appellation: "",
					age: "",
					occupation: "",
					membertype: 1,
				});
			},
			//删除一行 click
			report_onRemoveMoreForm(index, prop) {
				this.dynamicValidateForm[prop].splice(index, 1);
			},
			// 选择报读学历跟进人 click
			reportSelectBtn(url, modal, data) {
				const requireField = [{
						prop: "schoolorgid",
						message: "请先选择教学点",
					},
					{
						prop: "year",
						message: "请先选择招生年份",
					},
					{
						prop: "levelval",
						message: "请先选择层次",
					},
					{
						prop: "projecttype",
						message: "请先选择报读类型",
					},
				];
				for (let i in requireField) {
					if (!this.dynamicValidateForm[requireField[i].prop]) {
						return messageTip({
							type: "warning",
							message: requireField[i].message,
						});
					}
				}
				this.onSelectData(url, modal, data);
				this.dynamicValidateForm.guakaoschoolorgname = "";
				this.dynamicValidateForm.guakaocompanyorgname = "";
				this.dynamicValidateForm.classaddress = "";

			},
			// 选择报读学历跟进人确定
			reportDataComfirm() {
				selectCheck(this.multipleSelection, "操作", false, () => {
					this.dynamicValidateForm.majorname1 =
						this.multipleSelection[0].collegemajorname; // 报考专业1
					this.dynamicValidateForm.collegename1 =
						this.multipleSelection[0].collegename; //院校1
					this.dynamicValidateForm.grade = this.multipleSelection[0].grade; //招生年级
					this.showCollegemajorModal = false;
					this.handleForm();
					this.multipleSelection = [];
				});
			},
			//选择第一志愿函授站 click
			onCollegecoporgSelectData(url, modal, data) {
				if (!this.dynamicValidateForm.collegename1) return messageTip({
					type: "warning",
					message: '请先选择报考院校',
				});
				this.onSelectData(url, modal, data);
			},
			//确定第一志愿函授站 click
			onComfirmCollegecoporg() {
				selectCheck(this.multipleSelection, "操作", false, () => {
					this.dynamicValidateForm.guakaoschoolorgid = this.multipleSelection[0].schoolorgid;
					this.dynamicValidateForm.guakaocompanyorgid = this.multipleSelection[0].companyorgid;
					this.dynamicValidateForm.classaddressid = this.multipleSelection[0].classid;
					this.$set(this.dynamicValidateForm, 'guakaoschoolorgname', this.multipleSelection[0]
						.schoolorgname)
					this.$set(this.dynamicValidateForm, 'guakaocompanyorgname', this.multipleSelection[0]
						.companyorgname)
					this.$set(this.dynamicValidateForm, 'classaddress', this.multipleSelection[0].classname)
					this.showCollegecoporg = false;
					this.handleForm();
					this.multipleSelection = [];
					this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
				});
			},

			//检查是否可以收费 api
			checkincollect(id) {
				myRequest({
					url: student_checkincollect,
					data: {
						id: id,
					},
				}, {
					that: this,
					showModal: "showEnrolModal",
					methodName: "collect",
				});
			},

			//收学位费路由页 api
			collect() {
				myRequest({
					url: student_incollect,
					data: {
						id: this.multipleSelection[0].id,
					},
				}).then((res) => {
					this.dynamicValidateForm = Object.assign(
						res.data.data.tmoneyin,
						res.data.data.tstudent
					);
					this.org_comboxchild(this.dynamicValidateForm.companyorgid);
					delete this.dynamicValidateForm.inserttime;
					// this.getshoujuinfo();
				});
			},

			//获取收据编号  api
			getshoujuinfo() {
				myRequest({
					url: _shouju_getshoujuinfo,
					data: {
						schoolorgid: this.dynamicValidateForm.schoolorgid,
					},
				}).then((res) => {
					if (res.data.code === "200") {
						this.dynamicValidateForm.shoujuid = res.data.data.tshouju.id;
						this.dynamicValidateForm.shoujucode = res.data.data.tshouju.code;
					}
				});
			},

			//检查可否打印收据 api
			checkprintshouju(row) {
				myRequest({
					url: student_checkprintshouju,
					data: {
						id: row.id,
					},
				}).then((res) => {
					if (res.data.code === "200") {
						this.printReceipt(row);
					}
				});
			},

			//打印收据 api
			printReceipt(row) {
				myRequest({
					url: student_printshouju,
					data: {
						id: row.id,
						shoujuid: row.shoujuid,
						shoujucode: row.shoujucode,
					},
				}).then((res) => {
					if (res.data.code === "200") {
						window.open(
							this.$url.upload +
							"/shouju/" +
							row.id +
							res.data.data.tshouju.code +
							".pdf"
						);
					}
				});
			},

			//切换选项表格页
			handleSelectChange(val, data) {
				this.selectPageInfo.pageindex = val;
				this.onSelectData(this.selectTableUrl, null, data);
				this.onCloseSelect();
			},
			//选中表格数据改变时 change
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},

			//选项表格 api
			onSelectData(url, modal, data) {
				this.selectTableUrl = url;
				myRequest({
					url: this.selectTableUrl,
					data: Object.assign(data || {}, this.selectPageInfo),
				}).then((res) => {
					if (res.data.code === "200") {
						this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
						this.selectTable = res.data.data;
						this.key = Math.random();
						this[modal] = true;
					}
				});
			},
			//选项表格 - 搜索 click
			onSelectSearchClick(url, modal, data) {
				this.selectPageInfo.pageindex = 1;
				this.onSelectData(url, modal, data);
			},
			//关闭选项框 click
			onCloseSelect() {
				this.selectPageInfo.pageindex = 1;
			},

			// 确定 - 收入明细 click
			onConfirmMoneyinitem() {
				selectCheck(this.multipleSelection, "收入明细", false, () => {
					this.$set(
						this.dynamicValidateForm,
						"itemmoneyinitemname",
						this.multipleSelection[0].name
					);
					this.dynamicValidateForm.itemid = this.multipleSelection[0].id;
					this.showSelectItemModal = false;
					this.handleselect();
					this.multipleSelection = [];

				});
			},

			// 确定 - 收入二级类别 click
			onConfirmType() {
				selectCheck(this.multipleSelection, "二级类别", false, () => {
					this.$set(
						this.dynamicValidateForm,
						"typem2oneyintypename",
						this.multipleSelection[0].name
					);
					this.$set(
						this.dynamicValidateForm,
						"typem1oneyintypename",
						this.multipleSelection[0].type1name
					);
					this.$set(
						this.dynamicValidateForm,
						"typemoneyintypename",
						this.multipleSelection[0].typename
					);
					this.dynamicValidateForm.type2id = this.multipleSelection[0].id;
					this.dynamicValidateForm.type1id = this.multipleSelection[0].type1id;
					this.dynamicValidateForm.typeid = this.multipleSelection[0].typeid;
					this.showSelectTypeModal = false;
					this.handleselect();
					this.multipleSelection = [];
				});
			},

			// 确定 - 银行账户 click
			onConfirmAccount() {
				selectCheck(this.multipleSelection, "银行账户", false, () => {
					this.$set(
						this.dynamicValidateForm,
						"bankorcash",
						this.multipleSelection[0].bankorcash
					);
					this.$set(
						this.dynamicValidateForm,
						"accountname",
						this.multipleSelection[0].accountname
					);
					this.$set(
						this.dynamicValidateForm,
						"cardno",
						this.multipleSelection[0].cardno
					);
					this.dynamicValidateForm.accountid = this.multipleSelection[0].id;
					this.showSelectAccountModal = false;
					this.selectQuery();
					this.multipleSelection = [];
				});
			},

			// 确定 - 院校专业 click
			onConfirmCollegeMajor() {
				selectCheck(this.multipleSelection, "院校专业", false, () => {
					this.$set(
						this.dynamicValidateForm,
						"grade",
						this.multipleSelection[0].enrollyearval
					);
					this.$set(
						this.dynamicValidateForm,
						"collegename1",
						this.multipleSelection[0].collegename
					);
					this.$set(
						this.dynamicValidateForm,
						"majorname1",
						this.multipleSelection[0].majorname
					);
					this.$set(
						this.dynamicValidateForm,
						"leveltype1",
						this.multipleSelection[0].majorlevelval
					);
					this.dynamicValidateForm.collegeid1 =
						this.multipleSelection[0].collegeid;
					this.dynamicValidateForm.majorid1 = this.multipleSelection[0].majorid;
					this.showSelectCollegeModal = false;
				});
			},

			//提交收款 click
			onSubmitEnrolForm(dynamicValidateForm) {
				this.$refs[dynamicValidateForm].validate((valid) => {
					if (valid) {
						this.savecollect();
					}
				});
			},

			//保存收费 api
			savecollect() {
				myRequest({
					url: student_savecollect,
					data: {
						id: this.dynamicValidateForm.id,
						qianzaiid: this.dynamicValidateForm.qianzaiid,
						cardno: this.dynamicValidateForm.cardno,
						typeid: this.dynamicValidateForm.typeid,
						itemid: this.dynamicValidateForm.itemid,
						type2id: this.dynamicValidateForm.type2id,
						type1id: this.dynamicValidateForm.type1id,
						shoujuid: this.dynamicValidateForm.shoujuid,
						accountid: this.dynamicValidateForm.accountid,
						bankorcash: this.dynamicValidateForm.bankorcash,
						schoolorgid: this.dynamicValidateForm.schoolorgid,
						companyorgid: this.dynamicValidateForm.companyorgid,
						qianzainame: this.dynamicValidateForm.qianzainame,
						shouldrecvmoney: this.dynamicValidateForm.shouldrecvmoney,
						realrecvmoney: this.dynamicValidateForm.realrecvmoney,
						shoukuanrenark: this.dynamicValidateForm.shoukuanrenark,
						discountfee: this.dynamicValidateForm.discountfee,
						discountremarks: this.dynamicValidateForm.discountremarks,
						isbookfee: this.dynamicValidateForm.isbookfee,
					},
				}, {
					isRefTableList: true,
					that: this,
					modal: "showEnrolModal",
				});
			},

			//变更状态 api
			changeState(id, flag) {
				myRequest({
					url: student_changeflag,
					data: {
						id: id,
						flag: flag, //1正式，0非正式
					},
				}, {
					isRefTableList: true,
					that: this,
				});
			},
			//院校专业列表 api
			enrollplan() {
				myRequest({
					url: enrollplandetail_dialogresult,
					data: {
						pageindex: this.pageinfo.pageindex,
						pagesize: this.pageinfo.pagesize,
						year: this.dynamicValidateForm.year,
						schoolorgname: this.dynamicValidateForm.schoolorgname,
					},
				}).then((res) => {
					if (res.data.code === "200") {
						this.enrollplandetail = res.data.data;
						this.showSchoolTable = true;
					}
				});
			},
			//名族 api
			nation() {
				myRequest({
					url: dic_combox,
					data: {
						typecode: "nation",
					},
				}).then((res) => {
					if (res.data.code === "200") {
						this.nationList = res.data.data.list;
					}
				});
			},
			//政治面貌 api
			policy() {
				myRequest({
					url: dic_combox,
					data: {
						typecode: "policy",
					},
				}).then((res) => {
					if (res.data.code === "200") {
						this.policyList = res.data.data.list;
					}
				});
			},
			//招生年份 api
			enrollyear() {
				myRequest({
					url: dic_combox,
					data: {
						typecode: "enrollyear",
					},
				}).then((res) => {
					if (res.data.code === "200") {
						this.enrollyearList = res.data.data.list;
					}
				});
			},
			//层次类型 api
			leveltype() {
				myRequest({
					url: dic_combox,
					data: {
						typecode: "leveltype",
					},
				}).then((res) => {
					if (res.data.code === "200") {
						this.leveltypeList = res.data.data.list;
					}
				});
			},
			//项目类型 api
			leveltype() {
				myRequest({
					url: dic_combox,
					data: {
						typecode: "projecttype",
					},
				}).then((res) => {
					if (res.data.code === "200") {
						this.projecttypeList = res.data.data.list;
					}
				});
			},
			//学习形式 api
			studyType() {
				myRequest({
					url: dic_combox,
					data: {
						typecode: "studytype",
					},
				}).then((res) => {
					if (res.data.code === "200") {
						this.studyTypeList = res.data.data.list;
					}
				});
			},
		},
	};
</script>

<style lang="scss">
	@import "../../../assets/css/pulid.scss";
</style>
